import { configureStore, createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';
import kycReducer from './kycSlice';

const isSerializable = (value: any) => true;

const serializableMiddleware = createSerializableStateInvariantMiddleware({
    isSerializable,
});

function saveToLocalStorage(state: any) {
    try {
        const serialisedState = JSON.stringify(state);
        sessionStorage.setItem('persistantState', serialisedState);
    } catch (e) {
        console.warn(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serialisedState = sessionStorage.getItem('persistantState');
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

const store = configureStore({
    middleware: [serializableMiddleware],
    preloadedState: loadFromLocalStorage(),
    reducer: {
        kyc: kycReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
