import { Trans } from 'react-i18next';
import Button from '../button/Button';
import styles from './ActionBox.module.scss';

const ActionBox = () => (
  <section className={styles.actionContainer}>
    <p>
      <Trans i18nKey="startToday">
        Start today to receive <span>Blockchain payments</span> and gain access to more than 150M users
      </Trans>
    </p>
    <Button />
  </section>
);

export default ActionBox;
