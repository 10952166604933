/* eslint-disable no-param-reassign */
import axios from 'axios';
import i18next from 'i18next';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://kubepay.dev.api.kubegroup.co/';

const APIKit = axios.create({
    baseURL: BASE_URL,
});

// REQUEST INTERCEPTOR
APIKit.interceptors.request.use(
  async (config) => {
    config.headers = config.headers ?? {};
    config.headers['Accept-language'] = i18next.language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default APIKit;
