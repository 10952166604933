import styles from './RequirementBox.module.scss';

interface IProps {
    requirements: requirementObj[];
}

interface requirementObj {
    id: number;
    text: string;
}

const RequirementBox = ({ requirements }: IProps) => {
    return (
        <div className={styles.requirementContainer}>
            <ul>
                {requirements.map((requirement) => (
                    <li key={requirement.id}>{requirement.text}</li>
                ))}
            </ul>
        </div>
    );
};

export default RequirementBox;
