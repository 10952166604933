import { useTranslation } from 'react-i18next';
import styles from './Pricing.module.scss';
import InfoBox from './infoBox/InfoBox';
import IncludedFeatures from './includedFeatures/IncludedFeatures';
import ActionBox from './actionBox/ActionBox';

const Pricing = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className={styles.bgColoured}>
                <div className={styles.sectionBackground} />
                <div className={styles.innerContainer}>
                    <h1 className={styles.title}>{t('pricing')}</h1>
                    <p className={styles.subtitle}>{t('questionContactUs')}</p>
                </div>
            </section>
            <InfoBox />
            <IncludedFeatures />
            <ActionBox />
        </>
    );
};

export default Pricing;
