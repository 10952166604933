import styles from './UploadSucceed.module.scss';
import uploadSucceedIcon from '../../../../../assets/images/uploadSucceed-icon.svg';

const UploadSucceed = () => {
    return (
        <div className={styles.container}>
            <img src={uploadSucceedIcon} alt="Upload succeed icon" />
            <span>The file has been uploaded.</span>
        </div>
    );
};

export default UploadSucceed;
