import styles from './MessageBox.module.scss';
import greenCheckedIcon from '../../../../../assets/images/greenChecked-icon.svg';
import errorIcon from '../../../../../assets/images/error-icon.svg';
import informationIcon from '../../../../../assets/images/information-icon.svg';

interface IProps {
    text: string;
    type: string;
}

const MessageBox = ({ text, type }: IProps) => {
    const getIcon = () => {
        let icon;
        let altText;

        switch (type) {
            case 'success':
                icon = greenCheckedIcon;
                altText = 'checked';
                break;
            case 'error':
                icon = errorIcon;
                altText = 'error';
                break;
            case 'warning':
                icon = informationIcon;
                altText = 'warning';
                break;
            default:
                icon = errorIcon;
        }

        return {
            icon,
            altText,
        };
    };

    return (
        <div className={styles.messageBox}>
            <img src={getIcon().icon} alt={`${getIcon().altText} icon`} />
            <span>{text}</span>
        </div>
    );
};

export default MessageBox;
