import Cover from './cover/Cover';
import Design from './design/Design';
import styles from './Home.module.scss';
import Products from './products/Products';
import WhatIsKubePay from './whatIsKubePay/WhatIsKubePay';
import WhyKubePay from './whyKubePay/WhyKubePay';

const Home = () => {
    return (
        <div className={styles.homeContainer}>
            <Cover />
            <WhatIsKubePay />
            <Design />
            <WhyKubePay />
            <Products />
        </div>
    );
};

export default Home;
