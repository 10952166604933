/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface Customer {
    email: string;
    first_name: string;
    last_name: string;
    postal_code: string;
}

export type Document = 'dni' | 'passport' | 'driver' | 'residence' | '';

interface SliceState {
    created_at: string;
    operationUUID: string;
    verificationSecret: string;
    documentFront: File;
    countryId: string;
    selectedCountry: string;
    documentBack: File;
    selfiePic: File;
    proofOfAddress: File;
    docType: Document;
    customer: Customer;
    user_wallet: string;
    swap_to: string;
    final_price: string;
    final_price_currency: string;
}

const initialState: SliceState = {
    created_at: '',
    operationUUID: '',
    verificationSecret: '',
    countryId: '',
    selectedCountry: '',
    documentFront: new File([], ''),
    documentBack: new File([], ''),
    selfiePic: new File([], ''),
    proofOfAddress: new File([], ''),
    docType: '',
    user_wallet: '',
    swap_to: '',
    final_price: '',
    final_price_currency: '',
    customer: {
        email: '',
        first_name: '',
        last_name: '',
        postal_code: '',
    },
};

export const kycSlice = createSlice({
    name: 'kyc',
    initialState,
    reducers: {
        setOperationUUID: (state, action) => {
            state.operationUUID = action.payload;
        },
        setVerificationSecret: (state, action) => {
            state.verificationSecret = action.payload;
        },
        setCustomerData: (state, action) => {
            state.customer = action.payload;
        },
        setOperationData: (state, action) => {
            const { createdAt, userWallet, swapTo, finalPrice, finalPriceCurrency } = action.payload;
            state.created_at = createdAt;
            state.user_wallet = userWallet;
            state.swap_to = swapTo;
            state.final_price = finalPrice;
            state.final_price_currency = finalPriceCurrency;
        },
        setCountryId: (state, action) => {
            state.countryId = action.payload;
        },
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        setDocType: (state, action) => {
            state.docType = action.payload;
        },
        setDocumentFront: (state, action) => {
            state.documentFront = action.payload;
        },
        setDocumentBack: (state, action) => {
            state.documentBack = action.payload;
        },
        setProofOfAddress: (state, action) => {
            state.proofOfAddress = action.payload;
        },
        setSelfiePic: (state, action) => {
            state.selfiePic = action.payload;
        },
        resetKycStates: () => {
            return initialState;
        },
    },
});

export const {
    setOperationUUID,
    setVerificationSecret,
    setCustomerData,
    setOperationData,
    setDocumentFront,
    setCountryId,
    setSelectedCountry,
    setDocumentBack,
    setProofOfAddress,
    setSelfiePic,
    setDocType,
    resetKycStates,
} = kycSlice.actions;

export default kycSlice.reducer;
