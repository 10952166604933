import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import styles from './SelectCountry.module.scss';
import selectArrowIcon from '../../../../../assets/images/selectArrow-icon.svg';
import searchIcon from '../../../../../assets/images/search-icon.svg';
import CountryList from '../../../../../utils/CountryList';

interface IProps {
    setValue: Dispatch<SetStateAction<string>>;
    setId: Dispatch<SetStateAction<string>>;
    countrySelected: string;
    setCountrySelected: Dispatch<SetStateAction<string>>;
}

type countryObj = {
    id: string;
    shortCode: string;
    country: string;
};

const SelectCountry = ({ setValue, setId, countrySelected, setCountrySelected }: IProps) => {
    const [showDiv, setShowDiv] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [countries, setCountries] = useState<Array<countryObj>>([]);

    const handleBtnClick = (e: any) => {
        e.preventDefault();
        setShowDiv(true);
    };

    const handleCountryClick = (e: any, shortCode: string, country: string) => {
        e.preventDefault();
        setCountrySelected(country);
        setValue(country);
        setId(shortCode);
        setShowDiv(false);
    };

    const handleSearch = (e: any) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        setCountries(CountryList);
        if (countrySelected === '') setShowDiv(false);
    }, []);

    useEffect(() => {
        let filteredCountries;
        if (search !== '') {
            filteredCountries = countries.filter((element) => {
                return element.country.toLowerCase().includes(search.toLowerCase());
            });
        } else filteredCountries = CountryList;
        setCountries(filteredCountries);
    }, [search]);

    return (
        <div className={styles.dropdownContainer}>
            <button onClick={handleBtnClick}>{countrySelected}</button>
            <img src={selectArrowIcon} alt="Arrow icon" />
            {showDiv && (
                <div className={styles.dropdown}>
                    <div className={styles.searchContainer}>
                        <input
                            type="search"
                            onChange={handleSearch}
                            value={search}
                            id="search"
                            placeholder="Search..."
                            autoComplete="off"
                            maxLength={30}
                        />
                        <label htmlFor="search">Search...</label>
                        <img src={searchIcon} alt="Search icon" />
                    </div>
                    <div className={styles.listContainer}>
                        <div className={styles.innerListContainer}>
                            <ul>
                                {countries.length > 0 &&
                                    countries.map((country) => (
                                        <li
                                            key={country.id}
                                            onClick={(e) => handleCountryClick(e, country.shortCode, country.country)}
                                        >
                                            {country.country}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectCountry;
