import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import enGlobal from './en/global.json';
import esGlobal from './es/global.json';
import enFAQ from './en/faq.json';
import esFAQ from './es/faq.json';

export const resources = {
    en: {
        global: enGlobal,
        faq: enFAQ,
    },
    es: {
        global: esGlobal,
        faq: esFAQ,
    },
} as const;

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        defaultNS: 'global',
        resources,
        supportedLngs: ['en', 'es'],
        fallbackLng: 'en', // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        returnObjects: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
