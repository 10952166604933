import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import routes from '../utils/routes';

const useHelmet = () => {
    const location = useLocation();

    const getCurrentPathname = () => {
        const pathnameHasFinalSlash = location.pathname.match('/.*/$');
        if (pathnameHasFinalSlash) {
            const pathnameWithoutFinalSlash = location.pathname.replace(/\/+$/, '');
            return pathnameWithoutFinalSlash;
        }
        return location.pathname;
    };

    const currentPathname = getCurrentPathname();

    let title = 'KubePay | Payments made easy';
    let description =
        'Instantly make purchases with digital assets, from your everyday coffee to your next international flight. Paying digitally has never been easier.';

    if (currentPathname === routes.products) {
        title = 'KubePay | Products';
        description =
            'Discover all the products that are part of KubePay, from our own wallet to our payment method for companies.';
    }

    if (currentPathname === routes.pricing) {
        title = 'KubePay | Pricing';
        description = 'Check out the different plans and choose the one that best suits your business.';
    }

    if (currentPathname === routes.contact) {
        title = 'KubePay | Contact';
        description = 'You can contact our team to solve your doubts or if you need help in any process.';
    }

    if (currentPathname === routes.faqs) {
        title = 'KubePay | Frequent Asked Questions';
        description = 'To get started, take a look at some of our most frequently asked questions.';
    }

    if (currentPathname === routes.contactSales) {
        title = 'KubePay | Join Us';
        description = 'Contact our sales team and introduce digital currencies into your business.';
    }

    const isRouteToPreventIndexing = currentPathname === routes.terms || currentPathname === routes.privacy;

    const HelmetContainer = () => (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {isRouteToPreventIndexing && <meta name="robots" content="noindex" />}
            <link rel="canonical" href={`https://kubepay.io${currentPathname}`} />
        </Helmet>
    );

    return { HelmetContainer };
};

export default useHelmet;
