// @ts-nocheck
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Inputs.module.scss';
import arrowUp from '../../../assets/images/arrow-up.png';
import arrowDown from '../../../assets/images/arrow-down.png';
import useClickOutside from '../../../hooks/useClickOutside';

interface IProps {
    label: string;
    text: string;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
    selectionItems: Array<object>;
    errorMessage?: string;
}

interface IOptionsProps {
    selectionItems: Array<object>;
    onItemClick: any;
}

const SelectorInput = ({ label, text, value, setValue, selectionItems, errorMessage }: IProps) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState<boolean>(false);
    const selectorRef = useRef<any>(null);
    const onSelectorClick = () => setIsActive(!isActive);
    const onSelectionItemChange = (item: any) => setValue(item.title);

    const handleChange = (e: any) => setValue(e.target.value);

    const SelectorOptions = ({ selectionItems, onItemClick }: IOptionsProps) => (
        <div className={styles.selectorOptions}>
            <ul>
                {selectionItems.map((item: any, index: number) => (
                    <li key={item.id}>
                        <button
                            type="button"
                            onClick={() => onItemClick(item)}
                            tabIndex={index}
                            className={styles.selectionBtn}
                        >
                            <p>
                                <span>{t(`${item.title}`)}</span>
                                {item.subtitle && `(${t(`${item.subtitle}`, { numberEmployees: item.number })})`}
                            </p>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );

    useClickOutside(selectorRef, () => {
        if (isActive) {
            setIsActive(false);
        }
    });

    return (
        <div className={`${styles.formLine} `}>
            <label htmlFor={label} className={styles.label}>
                {t(`${label}`)}
            </label>
            <button
                type="button"
                className={`${styles.selectionBtn} ${styles.input} ${isActive && styles.overlay}`}
                onClick={onSelectorClick}
                ref={selectorRef}
            >
                <input
                    type="text"
                    placeholder={t(`${text}`)}
                    value={t(`${value}`)}
                    onChange={handleChange}
                    className={`${styles.selectionInput} `}
                    required
                />
                <div className={`${styles.selectorIcon} ${styles.selectionArrow}`}>
                    <img src={isActive ? arrowUp : arrowDown} alt="" />
                </div>
                {isActive && <SelectorOptions selectionItems={selectionItems} onItemClick={onSelectionItemChange} />}
            </button>
        </div>
    );
};

export default SelectorInput;
