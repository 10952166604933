import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import APIKit from '../../../utils/APIKit';
import handleError from '../../../utils/handleError';
import ReCAPTCHA from 'react-google-recaptcha';
import InputText from '../../global/inputs/InputText';
import PhoneInput from '../../global/inputs/PhoneInput';
import SelectorInput from '../../global/inputs/SelectorInput';
import TextArea from '../../global/inputs/TextArea';
import styles from './ContactSales.module.scss';
import validateEmail from '../../../utils/validateEmail';

const ContactSales = () => {
    const { t } = useTranslation();
    const [sendMessage, setSendMessage] = useState({ visible: false, error: false, message: '' });
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [web, setWeb] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [prefix, setPrefix] = useState<string>('+34');
    const [phone, setPhone] = useState<string>('');
    const [size, setSize] = useState<string>('');
    const [country, setCountry] = useState<string>('Spain');
    const [captchaValue, setCaptchaValue] = useState<string | null>('');

    const [firstNameError, setFirstNameError] = useState<string>('');
    const [lastNameError, setLastNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [webError, setWebError] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    const [messageError, setMessageError] = useState<string>('');
    const [captchaError, setCaptchaError] = useState<string>('');

    const sizeItems = [
        { id: 1, title: 'microSizedBusiness', subtitle: 'lessThan', number: '10' },
        { id: 2, title: 'smallSizedBusiness', subtitle: 'numEmployees', number: '10-49' },
        { id: 3, title: 'mediumBusiness', subtitle: 'numEmployees', number: '50-249' },
        { id: 4, title: 'largeSizedBusiness', subtitle: 'moreThan', number: '250' },
    ];

    const countries = [
        { id: 1, title: 'spain' },
        { id: 2, title: 'italy' },
        { id: 3, title: 'germany' },
        { id: 4, title: 'unitedKingdom' },
    ];

    const kubePayList = [
        { id: 1, text: 'introduceCurrency' },
        { id: 2, text: 'forgetTransactionFees' },
        { id: 3, text: 'instantlyReceivePayments' },
    ];

    const checkData = (data: any) => {
        const emailError = validateEmail(data.email);
        if (data.name === '') setFirstNameError('requiredField');
        if (data.surname === '') setLastNameError('requiredField');
        if (data.phone === '') setPhoneError('requiredField');
        if (data.web === '') setWebError('requiredField');
        if (data.message === '') setMessageError('requiredField');
        if (emailError.error.email !== '') setEmailError(emailError.error.email);
        if (captchaValue === '') setCaptchaError('checkCaptcha');

        return (
            data.name !== '' &&
            data.surname !== '' &&
            emailError.isValid &&
            data.phone !== '' &&
            data.web !== '' &&
            data.message !== '' &&
            captchaValue !== ''
        );
    };

    const sendData = (data: any) => {
        return APIKit.post('web/contact/', data)
            .then(() => setSendMessage({ visible: true, error: false, message: t('messageSend') }))
            .catch((error) => {
                const errorMessage = handleError(error);
                setSendMessage({ visible: true, error: true, message: errorMessage });
            });
    };

    const handleContactClick = (e: any) => {
        e.preventDefault();
        setFirstNameError('');
        setLastNameError('');
        setEmailError('');
        setPhoneError('');
        setWebError('');
        setCaptchaError('');

        const data = {
            name: firstName,
            surname: lastName,
            email,
            company_web: web,
            phone_country_code: prefix,
            phone,
            company_size: size,
            country,
            message,
            recaptcha: captchaValue,
        };

        const isDataValid = checkData(data);

        if (isDataValid) {
            sendData(data);
            setFirstName('');
            setLastName('');
            setEmail('');
            setWeb('');
            setPrefix('+34');
            setPhone('');
            setSize('');
            setCountry('Spain');
            setMessage('');
            setCaptchaValue('');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.sectionBackground} />
            <h1 className={styles.title}>{t('contactSales')}</h1>
            <div className={styles.contentBox}>
                <div className={styles.formWrapper}>
                    <form>
                        <InputText
                            type="text"
                            label="firstName"
                            text="Jane"
                            value={firstName}
                            setValue={setFirstName}
                            errorMessage={firstNameError}
                        />
                        <InputText
                            type="text"
                            label="lastName"
                            text="Doe"
                            value={lastName}
                            setValue={setLastName}
                            errorMessage={lastNameError}
                        />
                        <InputText
                            type="email"
                            label="workEmail"
                            text="jane.doe@example.com"
                            value={email}
                            setValue={setEmail}
                            errorMessage={emailError}
                        />
                        <PhoneInput
                            label="workPhone"
                            text="555 555 555"
                            prefix={prefix}
                            setPrefix={setPrefix}
                            phone={phone}
                            setPhone={setPhone}
                            errorMessage={phoneError}
                        />
                        <InputText
                            type="url"
                            label="companyWeb"
                            text="companyWeb"
                            value={web}
                            setValue={setWeb}
                            errorMessage={webError}
                        />
                        <SelectorInput
                            label="companySize"
                            text="selectRangeEmployees"
                            value={size}
                            setValue={setSize}
                            selectionItems={sizeItems}
                        />
                        <SelectorInput
                            label="country"
                            text="spain"
                            value={country}
                            setValue={setCountry}
                            selectionItems={countries}
                        />
                        <TextArea
                            label="comments"
                            text="tellAboutProject"
                            value={message}
                            setValue={setMessage}
                            errorMessage={messageError}
                        />
                        <div className={styles.recaptchaContainer}>
                            <ReCAPTCHA
                                sitekey="6LemsE4gAAAAAC_e6zQjkgXsH28coZeBqYEOsiNW"
                                onChange={(value: any) => setCaptchaValue(value)}
                                className={styles.recaptcha}
                            />
                            <span className={styles.errorMessage}>{captchaError}</span>
                        </div>
                        {sendMessage.visible && sendMessage.error && (
                            <span className={styles.errorMessage}>{sendMessage.message}</span>
                        )}
                        {sendMessage.visible && !sendMessage.error && (
                            <span className={styles.correctMessage}>{sendMessage.message}</span>
                        )}
                        <button type="button" className={styles.btn} onClick={handleContactClick}>
                            {t('contactSales')}
                        </button>
                    </form>
                </div>
                <div className={styles.kubePayCan}>
                    <div className={styles.innerCanContainer}>
                        <h2>{t('withKubePayYouCan')}</h2>
                        <ul>
                            {kubePayList.map((item) => (
                                <li key={item.id}>
                                    <span>{t(`${item.text}`)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSales;
