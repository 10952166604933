const PrivacyES = () => (
    <>
        <h1>Política de Privacidad</h1>
        <h2>Responsable del tratamiento</h2>
        <p>
            El responsable del tratamiento de los datos recabados es Kube Ecosystem, S.L. con NIF B67040998 (en
            adelante, el Titular) y domicilio en Gran Via 415, 08015, Barcelona, Spain, titular de la App KubePay (en
            adelante, Kubepay).
        </p>
        <p>
            La presente Política de Privacidad regula la recogida y tratamiento de los datos personales que sean
            proporcionados por los Usuarios, cuando accedan, naveguen o hagan uso de las funcionalidades de la App.
        </p>
        <h2>Recogida de datos, finalidades, base de legitimación y plazos de conservación:</h2>
        <ul>
            <li>
                <span>a. Contacto:</span> El Usuario puede ponerse en contacto, a través de los formularios establecidos
                a tal efecto en la App. Deberá facilitar sus datos identificativos, así como el motivo, asunto o
                cuestión. El Titular utilizará esos datos para tramitar la consulta y contactar con el Usuario.
                <p>La base jurídica del tratamiento de tales datos se sustenta en el consentimiento del Usuario.</p>
                <p>
                    El plazo de conservación de los datos para esta finalidad será un año, salvo que sean aplicables
                    otros plazos.
                </p>
            </li>
            <li>
                <span>b. Registro:</span> Mediante el registro en Kubepay, será necesario que el Usuario recuerde la
                frase de recuperación indicada, para la tramitación del registro en la App y crear su Wallet,
                permitiendo el acceso a las funcionalidades de su cuenta personal.
                <p>
                    La base jurídica del tratamiento de tales datos se sustenta en la relación contractual mantenida con
                    el Usuario.
                </p>
                <p>
                    Sus datos serán conservados mientras forme parte de Kubepay y se encuentre registrado, salvo que
                    sean aplicables otros plazos.
                </p>
            </li>
            <li>
                <span>c. Adquisición, venta e intercambio del token KubeCoin:</span> El Usuario en KubePay puede
                adquirir, vender o intercambiar la cantidad de tokens KubeCoin de su elección. Para ello, deberá
                facilitar aquellos datos necesarios para tramitar la gestión y correcta asignación de los tokens
                adquiridos, lo que incluye su dirección de Wallet.
                <p>La base jurídica del tratamiento de tales datos se sustenta en la ejecución de un contrato.</p>
                <p>
                    El plazo de conservación de los datos será el adecuado para cumplir con las obligaciones
                    contractuales y, en todo caso, hasta cinco años más por gestiones tributarias y para cubrir posibles
                    responsabilidades, o diez años por blanqueo de capitales en caso de que corresponda y la cantidad
                    adquirida supere los mil euros, salvo que sean aplicables otros plazos.
                </p>
            </li>

            <li>
                <span>d. Transacciones con monedas digitales:</span> A través de Kubepay, el Usuario podrá realizar o
                recibir pagos a través de monedas digitales, ya sean KUBEs, ADA u otro tipo de monedas digitales. Para
                ello, deberá facilitar aquellos datos necesarios para tramitar la transacción, lo que incluye su
                dirección de Wallet.
                <p>La base jurídica del tratamiento de tales datos se sustenta en la ejecución de un contrato.</p>
                <p>
                    El plazo de conservación de los datos será el adecuado para cumplir con las obligaciones
                    contractuales y, en todo caso, hasta cinco años más por gestiones tributarias y para cubrir posibles
                    responsabilidades, o diez años por blanqueo de capitales en caso de que corresponda y la cantidad
                    adquirida supere los mil euros, salvo que sean aplicables otros plazos.
                </p>
            </li>
            <li>
                <span>e. Compra de productos a través del Marketplace:</span> El Usuario para adquirir los productos
                disponibles en el Marketplace ofrecido en Kubepay tiene que facilitar los datos necesarios para tramitar
                la gestión de la compra y el pedido (lo que incluye su envío a través de empresas transportistas).
                <p>
                    La base jurídica del tratamiento de tales datos se sustenta en la relación contractual mantenida con
                    el Usuario.
                </p>
                <p>
                    En cuanto al plazo de conservación, será el adecuado para cumplir con las obligaciones contractuales
                    y, en todo caso, hasta cinco años más por gestiones tributarias y para cubrir posibles
                    responsabilidades, salvo que sean aplicables otros plazos.
                </p>
            </li>
            <li>
                <span>f. Claiming:</span> El Usuario que previamente al uso de Kubepay haya almacenado KUBEs en
                kubecoin.org, podrá recibir los mismos en Kubepay a través de la funcionalidad “Claim your KUBEs”,
                facilitando los datos necesarios para la gestión.
                <p>
                    La base jurídica del tratamiento de tales datos se sustenta en la relación contractual mantenida con
                    el Usuario.
                </p>
                <p>
                    En cuanto al plazo de conservación, será el adecuado para cumplir con las obligaciones contractuales
                    y, en todo caso, hasta cinco años más por gestiones tributarias y para cubrir posibles
                    responsabilidades, salvo que sean aplicables otros plazos.
                </p>
            </li>
            <li>
                <span>g. Almacenamiento de NFTs:</span> El Usuario podrá almacenar, visualizar y vender sus NFTs en
                Kubepay y su Marketplace. Para ello, deberá facilitar aquellos datos necesarios para gestionar el
                almacenamiento o la venta, lo que incluye su dirección de Wallet y el código del NFT.
                <p>La base jurídica del tratamiento de tales datos se sustenta en la ejecución de un contrato.</p>
                <p>
                    El plazo de conservación de los datos será el adecuado para cumplir con las obligaciones
                    contractuales y, en todo caso, hasta cinco años más por gestiones tributarias y para cubrir posibles
                    responsabilidades, o diez años por blanqueo de capitales en caso de que corresponda y la cantidad
                    adquirida supere los mil euros, salvo que sean aplicables otros plazos.
                </p>
            </li>
        </ul>
        <h2>Ejercicio de derechos</h2>
        <p>
            Asimismo, el Usuario puede revocar el consentimiento para el tratamiento, haciendo ejercicio de los derechos
            de acceso, rectificación, supresión, portabilidad, oposición y limitación al tratamiento, poniéndolo en
            conocimiento del Titular a través de un correo electrónico dirigido a privacy@flykube.com o indicándolo a la
            dirección arriba indicada, señalando como asunto “Protección de Datos”. En alguna ocasión especial, podremos
            solicitar al Usuario que acredite su identidad mediante documento oficial, por ejemplo para evitar el acceso
            a sus datos por parte de terceros.
        </p>
        <p>
            Por ultimo, el Usuario podrá comunicar cualquier modificación por escrito o solicitar la baja, indicándolo
            en cualquiera de las direcciones facilitadas.
        </p>
        <p>
            En cualquier momento, el Usuario podrá presentar una reclamación ante la Agencia Española de Protección de
            Datos para hacer valer sus derechos.
        </p>
        <h2>Modificación de la Política de privacidad</h2>
        <p>
            El Titular se reserva el derecho de modificar en cualquier momento su política de privacidad, respetando la
            legislación vigente en materia de protección de datos y previa la debida comunicación a los interesados.
        </p>
        <h2>Idioma aplicable a la presente política de privacidad</h2>
        <p>
            El idioma aplicable a esta Política de Privacidad es el español. Cualquier versión de la misma en idioma
            distinto es ofrecida para comodidad del Usuario y para facilitar su comprensión. No obstante, esta Política
            de Privacidad se regirá siempre por su versión en español.
        </p>
        <p>
            En caso de contradicción entre la Política de Privacidad en cualquier idioma y su versión en español,
            prevalecerá la versión en español.
        </p>
    </>
);

export default PrivacyES;
