import { Dispatch, SetStateAction } from 'react';
import styles from './InputCheckboxAndLabel.module.scss';

interface IProps {
    value: boolean;
    setValue: Dispatch<SetStateAction<boolean>>;
    name: string;
    labelText: string;
}

const InputCheckboxAndLabel = ({ value, setValue, labelText, name }: IProps) => {
    return (
        <div className={styles.container}>
            <input type="checkbox" checked={value} id={name} onChange={() => setValue(!value)} />
            <label htmlFor={name}>{labelText}</label>
        </div>
    );
};

export default InputCheckboxAndLabel;
