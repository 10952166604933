const TermsES = () => (
    <>
        <h1>Condiciones Generales y Licencia de Uso</h1>
        <h2>1. Identificación de las partes</h2>
        <p>
            1.1. Las Condiciones Generales y Licencia de Uso aquí recogidas son suscritas, de una parte por FLYKUBE,
            S.L. con NIF B67040998 (en adelante el Titular) y domicilio en Barcelona, Gran Via de les Corts Catalanes
            415, 08015, España, como titular de la aplicación Kubepay Wallet (en adelante, la App o Kubepay), y, de
            otra, por el Usuario, entendido como la persona física, mayor de edad que, de forma libre y voluntaria,
            descarga, instala o accede a Kubepay, independientemente de que haga uso o no de los servicios y
            herramientas allí ofrecidas en cada momento.
        </p>
        <p>
            1.2. El Usuario, al acceder, descargar o instalar Kubepay acepta las presentes Condiciones en lo que pueda
            ser aplicable a cualquier Usuario de Internet que, sencillamente, descarga la App. El Titular recomienda
            realizar una atenta lectura del presente documento, así como la revisión periódica de su contenido.
        </p>
        <h2>2. Edad mínima</h2>
        <p>
            La legislación española no prohíbe el intercambio de moneda fiduciaria por activo digital, o viceversa, a
            menores de 18 años. Sin embargo, no es recomendable el acceso, navegación y utilización de los servicios
            ofrecidos por Kubepay por menores de edad debido a la características que el ámbito de los activos
            digitales, presenta.
        </p>
        <h2>3. Kubepay</h2>
        <p>
            3.1. La Aplicación es una App puesta a disposición de los Usuarios de dispositivos móviles (tanto teléfonos
            móviles como tablets) con sistema operativo iOS o Android, a través de la tienda de aplicaciones de Apple,
            la App Store o de Google Play, con la finalidad de permitir que las personas físicas y las jurídicas paguen
            y reciban fácilmente pagos en monedas digitales y, en concreto, para que los Usuarios envíen o reciban la
            moneda digital Cardano (ADA) y los tokens Kubecoin. No se requiere estar registrado y suscrito a los
            servicios ofrecidos siendo una billetera anónima y descentralizada. Además, los Usuarios podrán visualizar
            los precios pasados y presentes de los tokens, valorados tanto en monedas digitales como en moneda
            fiduciaria.
        </p>
        <p>
            3.2. La descarga e instalación de Kubepay son de carácter gratuito, sin perjuicio de que su uso sea de pago,
            en lo que se refiere al pago de tasas por las transacciones o la venta de productos y activos digitales.
        </p>
        <p>
            3.3. En Kubepay también se ofrecen medios de contacto con el Titular, a efectos de poder formular dudas,
            quejas o sugerencias enviando un correo a info@kubepay.io.
        </p>
        <p>
            3.4. La App de Kubepay está diseñada para ser empleada en dispositivos con acceso a Internet. No obstante,
            la App no incluye el acceso a Internet o a las redes de telefonía móvil, por lo que el Usuario deberá pagar
            las tarifas del proveedor de acceso a Internet u operador móvil correspondiente. Consulta antes las
            condiciones de acceso y tarificación de tu proveedor.
        </p>
        <h2>4. Obligaciones del Usuario</h2>
        <p>El Usuario se compromete a:</p>
        <ul>
            <li>
                a. Hacer un uso diligente, correcto y lícito de Kubepay, respetando la legislación vigente (y de manera
                especial la relativa a protección de datos y propiedad intelectual e industrial), la moral y las buenas
                costumbres, así como el orden público.
            </li>
            <li>
                b. Revisar periódicamente las presentes Condiciones Generales y Licencia de Uso, o cualesquiera otras
                condiciones resultaren aplicables, comprobando los cambios que, en su caso, pudieran haber sufrido.
            </li>
            <li>
                c. Controlar las notificaciones que, en su caso, le sean remitidas por el Titular, puesto que pueden
                incluir información importante.
            </li>
            <li>
                d. No utilizar Kubepay con fines comerciales; por ejemplo, recabando información o contenidos para
                prestar otros servicios que puedan suponer una clara competencia para el Titular.
            </li>
            <li>
                e. No modificar o tratar de modificar Kubepay en modo alguno ni realizar acciones o utilizar medios
                orientados a simular su apariencia o sus funciones.
            </li>
            <li>
                f. Abstenerse de llevar a cabo acción alguna que conlleve la introducción de virus informáticos,
                gusanos, troyanos o cualquier otra clase de código malicioso destinado a interrumpir, destruir o limitar
                las funcionalidades de Kubepay.
            </li>
            <li>
                g. No emplear técnicas de ingeniería inversa y/o descifrar, descompilar o utilizar cualquier otro
                sistema destinado a conocer el código fuente de Kubepay o de cualquier elemento sujeto a copyright o
                propiedad intelectual subyacente.
            </li>
            <li>
                h. No dañar, deshabilitar, sobrecargar o dificultar el servicio (o la red o redes conectadas al
                servicio), o interferir en el uso y disfrute del mismo.
            </li>
            <li>
                i. En cualquier caso, no realizar ningún tipo de actos que puedan infringir derechos o intereses del
                Titular o de terceros como puedan ser, a modo de ejemplo, derechos de propiedad intelectual o industrial
                (patentes, marcas, derechos de copyright, secretos comerciales...).
            </li>
            <li>j. Respetar lo dispuesto en los presentes textos legales.</li>
        </ul>
        <h2>5. Funcionamiento de Kubepay</h2>
        <p>Una vez el Usuario cree un Wallet en Kubepay podrá hacer uso de las siguientes funcionalidades:</p>
        <ul>
            <li>
                a. Comprar servicios y/o productos de la familia Kube (Flykube y EatKube) o de los Partners de Kube
                Ecosystem, S.L..
            </li>
            <li>
                b. Comprar y vender NFTs en el Marketplace incorporado en Kubepay. Además podrá usar Kubepay como
                almacenamiento de todos sus NFTs.
            </li>
            <li>c. Realizar staking de sus KUBEs para conseguir recompensas.</li>
            <li>
                d. Pagar en establecimientos mediante Kubepay para adquirir productos, enviando KUBEs a otros usuarios o
                establecimientos.
            </li>
            <li>e. Intercambiar los KUBEs con Cardano, cobrando Kubepay una comisión por el intercambio.</li>
            <li>
                f. Realizar pagos wireless instantáneos entre usuarios b2c o de usuario al proveedor/comercio a través
                de la Payment Gateway de Kubepay. Esto conlleva el pago de comisiones que varía en el tiempo y es
                diferente dependiendo del Usuario.
            </li>
        </ul>
        <h2>6. Condiciones Particulares de Registro</h2>
        <p>
            Las presentes Condiciones Particulares de Registro son suscritas de un lado por Kubepay, y de otro, por
            aquel Usuario que haya completado los pasos indicados en la App destinados a llevar a cabo la creación de su
            Wallet, junto con la aceptación de estas Condiciones Particulares.
        </p>
        <p>6.1. Requisitos y procedimiento para crear un Wallet</p>
        <p>
            Para generar un Wallet el Usuario deberá establecer las frase de recuperación, que se compone de 12 palabras
            aleatorias. Tras ello, deberá completar todos los pasos indicados, entre los que se incluye la lectura de la
            información relativa a la Política de Privacidad y de las presentes Condiciones.
        </p>
        <p>Una vez realizado lo anterior, el Usuario pasará a ser Usuario Registrado.</p>
        <p>
            Si el Usuario Registrado, no recuerda su frase de recuperación debe tener en cuenta que no podrá acceder a
            su Wallet, ya que no se puede restablecer.
        </p>
        <p>6.2. Kubepay</p>
        <p>
            La creación de un Wallet en Kubepay permite que los Usuarios Registrados envíen o reciban los tokens
            Kubecoin, la moneda digital Cardano (ADA) y otras monedas digitales. Además, los Usuarios Registrados podrán
            visualizar los precios pasados y presentes de los tokens, valorados tanto en monedas digitales como en
            moneda fiduciaria.
        </p>
        <p>6.3. Obligaciones del Usuario Registrados</p>
        <p>El Usuario Registrado tiene las siguientes obligaciones:</p>
        <ul>
            <li>
                a. Mantener y guardar con seguridad la dirección del Wallet, la frase de recuperación y la clave privada
                que da acceso a las funcionalidades de Kubepay, así como cualquier otro código que se necesite para el
                correcto uso de Kubepay.
            </li>
            <li>
                b. No compartir con terceros la dirección del Wallet, la frase de recuperación y la clave privada, así
                como, los otros códigos necesarios para el funcionamiento de Kubepay.
            </li>
        </ul>
        <h2>7. Propiedad intelectual e industrial y Licencia de Uso</h2>
        <p>
            7.1. En virtud de la aceptación de estas Condiciones y siempre y cuando el Usuario cumpla con todas sus
            obligaciones conforme a lo previsto en estas Condiciones o en cualesquiera otras condiciones resultaren
            aplicables, el Titular otorga al Usuario un derecho de uso de la App, tal y como ésta se ofrezca en cada
            momento, no exclusivo, sin posibilidad de cesión o sublicencia, en todo el mundo y por todo el tiempo que la
            App permanezca instalada en el dispositivo del Usuario, en cuya virtud:
        </p>
        <ul>
            <li>
                a. El Usuario queda autorizado para la descarga e instalación ilimitada de la App en su dispositivo
                móvil, si bien podrán implementarse medidas destinadas a limitar el número de conexiones simultáneas
                desde un solo lugar o dirección IP simultáneamente.
            </li>
            <li>
                b. Asimismo, el Usuario queda autorizado para acceder y utilizar la App, siempre y cuando ésta se
                encuentre activa y disponible, para fines particulares y privados.
            </li>
        </ul>
        <p>
            7.2. En ningún caso el Usuario queda facultado en virtud de esta licencia de uso para fines comerciales y/o
            distintos de los previstos en el apartado anterior.
        </p>
        <p>
            7.3. Esta licencia de uso no supone la cesión de ningún derecho de propiedad intelectual o industrial sobre
            la App o sobre cualquier contenido presente en la misma, siendo los derechos de propiedad intelectual sobre
            la App y todos los elementos que los conforman (incluyendo la programación, el diseño, las aplicaciones, los
            gráficos, los códigos, texto o imágenes allí presentes, así como los contenidos publicados y la tecnología
            asociada a la misma) de la titularidad exclusiva del Titular o contando ésta con los derechos o
            autorizaciones bastantes para su explotación. En consecuencia, queda prohibida la reproducción,
            distribución, comunicación pública, puesta a disposición o transformación de Kubepay o de cualquier elemento
            integrado en la App.
        </p>
        <p>
            7.4. De igual forma, el nombre de dominio, las marcas, nombres comerciales, y en general, cualquier signo
            distintivo relativo a Kubepay es de la titularidad del Titular o éste cuenta con las licencias necesarias
            para utilizarlos.
        </p>
        <p>
            7.5. Si detectas alguna infracción de los derechos de propiedad intelectual y/o industrial en Kubepay, te
            pedimos que nos lo comuniques a la mayor brevedad escribiendo a la dirección de correo electrónico
            info@kubein.io.
        </p>
        <h2>8. Enlaces a terceros</h2>
        <p>
            8.1. Es posible que Kubepay pueda incluir links o enlaces a páginas o sitios web de terceros. El Titular no
            asume ninguna responsabilidad sobre los mismos o sobre su contenido o correcto funcionamiento, ni sobre las
            consecuencias que se deriven del acceso a los mismos.
        </p>
        <p>
            8.2. Respecto de los enlaces publicados por el Titular, el Usuario reconoce y acepta que tales enlaces
            dirigen a sitios externos y ajenos, tanto a Kubepay como al Titular y que éste no aprueba ni revisa las
            funciones, publicidad o, en general, el contenido incluido en páginas de terceros, aún cuando sean enlazados
            desde Kubepay; por ello, ésta no puede garantizar que en los mismos no existan amenazas informáticas, virus
            o malware o que alberguen contenido ilícito o inadecuado u otros enlaces que, a su vez, lleven a sitios con
            alguna o varias de las anteriores características.
        </p>
        <p>
            8.3. La inclusión de enlaces a sitios web de terceros sobre los Servicios de Kubepay no implica la
            aprobación o aceptación del sitio web vinculado por los Titulares. Si el Usuario decide abandonar las
            páginas oficiales de Kubepay y acceder a sitios web de terceros, lo hace bajo su propio riesgo. Kubepay
            recomienda leer las condiciones de uso y las políticas de privacidad de los sitios web de terceros.
        </p>
        <h2>9. Exclusión de responsabilidad</h2>
        <p>
            9.1. El Titular trabaja para que los servicios y funcionalidades de Kubepay se encuentren siempre
            disponibles. No obstante, Kubepay será mostrada “tal cual”, según la disponibilidad y según las limitaciones
            concurrentes en cada momento.
        </p>
        <p>
            9.2. A pesar del continuo esfuerzo realizado por el Titular para proteger los sistemas y los contenidos
            incluidos en Kubepay, a cuyo efecto emplea los estándares de seguridad habituales en Internet, no es posible
            ofrecer plenas garantías en relación a las intrusiones o pérdidas de información que puedan producirse. Del
            mismo modo que no puede garantizarse la ausencia de virus o de otros elementos dañinos en Kubepay o en
            sitios de terceros que puedan producir alteraciones en el sistema informático, tanto software como hardware,
            del Usuario. Por esta razón, el Usuario asume y comprende que existan situaciones que puedan escapar al
            control del Titular.
        </p>
        <p>
            9.3. El Titular se exime de toda responsabilidad por el acceso indebido a contenidos como consecuencia de la
            creación de un Wallet.
        </p>
        <p>
            9.4. El Titular declina toda responsabilidad que se derive de un mal uso de la App por el Usuario de
            Kubepay, así como por el incumplimiento de las obligaciones o compromisos asumidos en virtud de las
            presentes Condiciones Generales o en cualesquiera otras que resultaran aplicables.
        </p>
        <p>
            9.5. En concreto, el Titular no será responsable de la pérdida de las monedas digitales o tokens, en caso de
            que el Usuario haya perdido u olvidado su dirección de Wallet, su clave privada o la frase de recuperación,
            así como los restantes códigos que puedan aplicarse, o se las hayan sustraído. En este caso, el Usuario
            reconoce que existe la posibilidad de que no pueda recuperar sus KUBEs o cualquier otra moneda digital, ya
            que dichos datos solo están bajo el conocimiento del Usuario.
        </p>
        <p>
            9.6. Con carácter general, ni el Titular ni sus colaboradores serán responsables en caso de lucro cesante o
            daño emergente por cualquier cuestión.
        </p>
        <p>
            9.7. Kubepay en ningún caso será responsable de cómo se use la App, ni de lo que se comparta en cualquier
            red o por cualquier medio a través de Kubepay. Tampoco lo será si incumple cualquier obligación o compromiso
            de estos términos o de la Licencia de Uso. El Usuario debe usar la App con responsabilidad.
        </p>
        <p>
            9.8. No garantizamos ni declaramos que la utilización por parte del Usuario no viole derechos de terceros.
            En tal caso, será único responsable el Usuario.
        </p>
        <h2>10. Exclusión de responsabilidad relacionada con los activos digitales</h2>
        <p>
            10.1. El Titular no se hace responsable de los daños y perjuicios que puedan surgir de o en relación con el
            uso del Wallet. Esto incluye, pero no se limita a, pérdida directa, pérdida del negocio o ganancias, daños
            causados al dispositivo del Usuario y los datos contenidos en el mismo, así como cualquier otro daño directo
            o indirecto, consecuente e incidental.
        </p>
        <p>
            10.2. El Titular es una entidad independiente y no es en ningún caso un agente fideicomisario cuya principal
            función consista en la puesta a disposición de monedas digitales y su posterior control en el mercado de
            divisas digitales.
        </p>
        <p>
            10.3. El Titular no es responsable de las pérdidas o problemas que puedan surgir de transacciones de activos
            digitales con terceros ni de las tasas cobradas por la red blockchain sobre la que se basa Kubepay.
        </p>
        <p>
            10.4. El Titular en ningún caso es responsable de las fluctuaciones de las divisas digitales que puedan
            derivarse.
        </p>
        <p>
            10.5. El Usuario asume que los protocolos subyacentes a Kubepay pueden estar sujetos a cambios repentinos en
            las reglas de funcionamiento de la cadena principal ("bifurcaciones" o “forks”), y que dichas bifurcaciones
            pueden afectar materialmente al valor, y/o al funcionamiento del ADA o de cualquier otra divisa digital que
            el Usuario almacene en Kubepay. En este caso, el Usuario reconoce y acepta que el Titular puede suspender
            temporalmente la App y, a su discreción, decidir si re-configurar sus sistemas o dejar de soportar el
            protocolo bifurcado, ofreciendo la posibilidad de retirar los fondos. Por este hecho, el Titular se exime de
            responsabilidad.
        </p>
        <p>
            10.6. El Titular no se hace responsable de cualquier mal funcionamiento, avería, retraso o interrupción de
            la conexión a Internet, o si por cualquier otra razón la App no está disponible por cualquier período de
            tiempo. Asimismo, no se hace responsable de los problemas que puedan derivarse de los smart contracts
            correspondientes, ni de los problemas derivados del código de la plataforma sobre la que Kubepay funciona.
        </p>
        <p>
            10.7. El Titular en ningún caso es una institución financiera asegurada. Como resultado, a diferencia de los
            fondos mantenidos en la mayoría de los bancos o cooperativas de ahorro y crédito, las monedas digitales
            puestas a disposición en las plataformas no están aseguradas contra pérdidas.
        </p>
        <h2>11. Indemnización</h2>
        <p>
            11.1. Si se produjeran cualquier tipo de daños, perjuicios, pérdidas o costes (incluidos los honorarios de
            abogados y procuradores), sufridos por el Titular como consecuencia de un incumplimiento por parte del
            Usuario de las presentes Condiciones Generales y Licencia de Uso o cualesquiera otras aplicables, en tal
            caso traerá consigo la obligación de resarcir al Titular.
        </p>
        <p>
            11.2. Del mismo modo ocurrirá en caso de que, como consecuencia de los incumplimientos del Usuario, se
            produjeran reclamaciones de terceros contra el Titular, en cuyo caso, aquél dejará indemne a éste,
            pudiéndosele reclamar cualquier gasto, coste, daño o perjuicio derivado de sus acciones.
        </p>
        <h2>12. Modificaciones</h2>
        <p>
            12.1. Las presentes Condiciones pueden ser en cualquier momento modificadas y/o actualizadas sin necesidad
            de previo aviso. Las modificaciones efectuadas entrarán en vigor a partir de su publicación en la App, sea
            cual sea el medio y forma empleado para ello.
        </p>
        <p>
            12.2. La modificación solo afectará a los Usuarios que las hubieran aceptado con posterioridad a dicha
            modificación.
        </p>
        <h2>13. Duración, resolución y exclusión</h2>
        <p>
            13.1. La duración de estas Condiciones es de carácter indefinido y se extiende por todo el tiempo que el
            Usuario mantenga su cuenta en Kubepay.
        </p>
        <p>
            13.2. En cualquier momento, el Usuario podrá resolver estas Condiciones simplemente cancelando su cuenta de
            Usuario a través de las herramientas de la App destinadas a tal efecto.
        </p>
        <p>
            13.3. Por su parte, el Titular se reserva el derecho de resolver estas Condiciones o de bloquear o eliminar
            la cuenta del Usuario cuando el incumplimiento grave y/o reiterado de las condiciones aquí expuestas o de
            cualesquiera otras condiciones que le fueran de aplicación.
        </p>
        <h2>14. Protección de datos</h2>
        <p>
            14.1. La recogida y tratamiento de los datos personales del Usuario recabados por el Titular a través de
            Kubepay se rige por una Política de Privacidad que puede conocerse accediendo a la misma y que se encuentra
            publicada en la App de Kubepay, y previamente en la tienda de aplicaciones.
        </p>
        <p>
            14.2. En cualquier momento el Usuario Registrado podrá eliminar o resetear su Wallet a través del apartado
            “Settings”.
        </p>
        <h2>15. Salvaguarda e interpretación</h2>
        <p>15.1. Estas Condiciones constituyen un acuerdo único entre el Usuario y el Titular.</p>
        <p>
            15.2. Si la Autoridad competente declara alguna disposición como ilegal, inválida o no ejecutable, supondrá
            que la misma deba ser interpretada de la manera más próxima a la intención original de tal disposición. No
            obstante, tal declaración respecto de alguna o algunas cláusulas no perjudicará la validez de las restantes.
        </p>
        <p>
            15.3. La no exigencia por parte del Titular del cumplimiento estricto de alguno de los términos de estas
            Condiciones no constituye ni podrá interpretarse en ningún caso como una renuncia por su parte a exigirlo en
            un futuro.
        </p>
        <h2>16. Idioma</h2>
        <p>
            16.1. El idioma aplicable a estas Condiciones es el español. Si se ofrece una versión en inglés es por mera
            cortesía, para la comodidad del Usuario. Por ello el Usuario acepta expresamente que las mismas se rijan por
            su versión en español.
        </p>
        <p>
            16.2. Si existiera alguna contradicción entre lo indicado en la versión en español de tales Condiciones y lo
            que se indica en la traducción, en todo caso prevalecerá la versión española.{' '}
        </p>
        <h2>17. Legislación y fuero</h2>
        <p>
            Las relaciones entre el Titular y el Usuario, se regirán por la legislación española y, en caso de conflicto
            en la interpretación o cumplimiento de las presentes Condiciones se someterán, con renuncia expresa a
            cualquier otro fuero que pudiera corresponderles, salvo que por Ley se determine de forma imperativa otro
            fuero distinto, a los Juzgados y Tribunales de Barcelona.
        </p>
        <h2>18. Términos y Condiciones del Programa de Staking de KubeCoin</h2>
        <p>
            Bienvenido al Programa de Staking de KubeCoin facilitado por la aplicación KubePay. Al participar en este
            programa, aceptas los siguientes términos y condiciones:
        </p>
        <p>Niveles de Staking y Períodos de Bloqueo</p>
        <p>
            Como participante en el Programa de Staking de KubeCoin, puedes elegir entre los siguientes niveles, cada
            uno con diferentes Rendimientos Porcentuales Anuales (APY) y períodos de bloqueo:
        </p>
        <p>
            <b> Nivel Explorador:</b>
        </p>
        <p> - APY: 3.5.%</p>
        <p> - Período de Bloqueo: Sin período de bloqueo. Los activos pueden moverse libremente sin restricciones.</p>
        <p>
            <b> Nivel Viajero:</b>
        </p>
        <p> - APY: 7.77%</p>
        <p>
            - Período de Bloqueo: Hasta 6 meses. Durante este tiempo, los activos apostados bajo este nivel no pueden
            moverse ni retirarse.
        </p>
        <p>
            <b> Nivel Creyente:</b>
        </p>
        <p> - APY: 10.5%</p>
        <p>
            {' '}
            - Período de Bloqueo: Hasta 12 meses. Durante este tiempo, los activos apostados bajo este nivel no pueden
            moverse ni retirarse.
        </p>
        <h3>Implicaciones Legales del Bloqueo de Activos</h3>
        <p>
            Al participar en los niveles Viajero y Creyente, reconoces y aceptas que tus activos apostados estarán
            bloqueados durante la duración especificada y no podrán ser accesibles para ninguna transacción fuera del
            programa de staking. Este bloqueo es esencial para mantener la integridad y estabilidad de la distribución
            de las recompensas de staking.
        </p>
        <h3>Límites de Staking</h3>
        <p>
            Para asegurar una participación justa y prevenir la monopolización del proceso de staking, se pueden aplicar
            los siguientes límites de staking:
        </p>
        <p>
            {' '}
            • Ningún usuario puede apostar más del Z% del suministro total de KUBE por nivel de staking. Este límite
            está diseñado para prevenir la concentración de suministro y asegurar una amplia participación a través de
            la red.
        </p>
        <h3>Derechos Reservados</h3>
        <p>
            Kube Ecosystem se reserva el derecho de modificar los términos del programa de staking, incluyendo, pero no
            limitado a, las tasas de APY, los períodos de bloqueo y los límites de staking. Los cambios serán
            comunicados a través de canales oficiales y entrarán en vigor desde la fecha especificada en la
            notificación.
        </p>
        <p>
            Al aceptar estos términos, te comprometes a cumplir con las reglas y regulaciones descritas en el Programa
            de Staking de KubeCoin y comprendes las restricciones y condiciones de tu participación.
        </p>
        <h3>Responsabilidad por el Staking</h3>
        <p>
            Al participar en el Programa de Staking de KubeCoin, reconoces y aceptas la plena responsabilidad por los
            fondos que comprometes al staking. Aunque hemos realizado una auditoría del contrato inteligente para
            asegurar su integridad operativa, Kube Ecosystem no se hace responsable de pérdidas que puedan ocurrir
            debido a fallos técnicos, violaciones de seguridad u otras circunstancias imprevistas. Al aceptar estos
            términos, te comprometes bajo tu propia responsabilidad; si se pierden fondos, la responsabilidad recae
            sobre ti y no podrás reclamar compensación o responsabilidad a Kube Ecosystem por tales pérdidas.
        </p>
    </>
);

export default TermsES;
