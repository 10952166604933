// @ts-nocheck
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import styles from './Inputs.module.scss';

interface IProps {
    type: string;
    label: string;
    text: string;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
    errorMessage: string;
}

const InputText = ({ type, label, text, value, setValue, errorMessage }: IProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.formLine}>
            <label htmlFor={label} className={styles.label}>
                {t(`${label}`)}
            </label>
            <div className={styles.inputWrapper}>
                <input
                    type={type}
                    placeholder={t(`${text}`)}
                    value={value}
                    onChange={(e: any) => setValue(e.target.value)}
                    className={styles.input}
                    required
                />
                <span className={styles.errorMessage}>{t(`${errorMessage}`)}</span>
            </div>
        </div>
    );
};

export default InputText;
