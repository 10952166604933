import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { setDocumentFront, setDocumentBack } from '../../../../redux/kycSlice';
import styles from './UploadDocument.module.scss';
import Button from '../commons/buttons/Button';
import { routes } from '../../../../utils/routes';
import RequirementBox from '../commons/requirementBox/RequirementBox';
import InputFile from '../commons/inputFile/InputFile';
import MessageBox from '../commons/messageBox/MessageBox';
import UploadSucceed from '../commons/uploadSucceed/UploadSucceed';
import redoActionIcon from '../../../../assets/images/redoAction-icon.svg';
import StepIndicator from '../commons/stepIndicator/StepIndicator';

const UploadDocument = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const docType = useSelector((state: RootState) => state.kyc.docType);
    const documentFront = useSelector((state: RootState) => state.kyc.documentFront);
    const documentBack = useSelector((state: RootState) => state.kyc.documentBack);
    const [frontDocument, setFrontDocument] = useState<any>(new File([], ''));
    const [backDocument, setBackDocument] = useState<any>(new File([], ''));
    const [errorsFront, setErrorsFront] = useState<string>('');
    const [errorsBack, setErrorsBack] = useState<string>('');
    const [hasFrontUploaded, setHasFrontUploaded] = useState<boolean>(false);
    const [hasBackUploaded, setHasBackUploaded] = useState<boolean>(false);
    const [reUpload, setReUpload] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (errorsFront === '' && errorsBack === '') {
            dispatch(setDocumentFront(frontDocument));
            dispatch(setDocumentBack(backDocument));
            navigate(routes.kycProofOfAddess);
        }
    };

    const handleBack = () => {
        navigate(routes.kycDocumentType);
    };

    const handleReupload = (e: any) => {
        e.preventDefault();
        setHasFrontUploaded(false);
        setHasBackUploaded(false);
        setFrontDocument(new File([], ''));
        setBackDocument(new File([], ''));
        dispatch(setDocumentFront(new File([], '')));
        dispatch(setDocumentBack(new File([], '')));
        setReUpload(false);
        setIsDisabled(true);
    };

    const requirements = [
        { id: 1, text: 'Bright and clear (good quality).' },
        { id: 2, text: 'Uncut (all corners of the document should be visible).' },
    ];

    useEffect(() => {
        if ('name' in documentFront && documentFront.name !== '') {
            setFrontDocument(documentFront);
            setHasFrontUploaded(true);
            setReUpload(true);
        } else setHasFrontUploaded(false);

        if ('name' in documentBack && documentBack.name !== '') {
            setBackDocument(documentBack);
            setHasBackUploaded(true);
        } else setHasBackUploaded(false);
    }, [documentFront, documentBack]);

    useEffect(() => {
        if (docType === 'passport' && hasFrontUploaded) setIsDisabled(false);
        if (hasFrontUploaded && hasBackUploaded) setIsDisabled(false);
    }, [hasFrontUploaded, hasBackUploaded]);

    return (
        <section className={styles.stepContainer}>
            <div className={styles.innerStepContainer}>
                <h3 className={styles.pageTitle}>Take a photo of your document</h3>
                <StepIndicator step={3} handleClick={handleBack} />
                <div className={styles.paragContainer}>
                    <p>Take a photo of your document.</p>
                    <p>The photo should be:</p>
                </div>
                <RequirementBox requirements={requirements} />
                <form noValidate>
                    {docType === 'passport' && hasFrontUploaded && <UploadSucceed />}
                    {docType === 'passport' && !hasFrontUploaded && (
                        <InputFile
                            setValue={setFrontDocument}
                            name="upload1"
                            setErrors={setErrorsFront}
                            setHasUploaded={setHasFrontUploaded}
                            setReUpload={setReUpload}
                        >
                            Upload your document
                        </InputFile>
                    )}

                    {docType !== 'passport' && hasFrontUploaded && <UploadSucceed />}
                    {docType !== 'passport' && !hasFrontUploaded && (
                        <InputFile
                            setValue={setFrontDocument}
                            name="upload1"
                            setErrors={setErrorsFront}
                            setHasUploaded={setHasFrontUploaded}
                            setReUpload={setReUpload}
                        >
                            Upload the <span>front</span> of your document
                        </InputFile>
                    )}

                    {docType !== 'passport' && hasBackUploaded && <UploadSucceed />}
                    {docType !== 'passport' && !hasBackUploaded && (
                        <InputFile
                            setValue={setBackDocument}
                            name="upload2"
                            setErrors={setErrorsBack}
                            setHasUploaded={setHasBackUploaded}
                            setReUpload={setReUpload}
                        >
                            Upload the <span>back</span> of your document
                        </InputFile>
                    )}
                    <div className={styles.messageContainer}>
                        {errorsFront !== '' && <MessageBox text={errorsFront} type="error" />}
                        {errorsBack !== '' && <MessageBox text={errorsBack} type="error" />}
                    </div>
                    {reUpload && (
                        <div className={styles.buttonContainer}>
                            <Button dark onClick={handleReupload} className={styles.reuploadBtn}>
                                <img src={redoActionIcon} alt="re do action icon" />
                                <span>Reupload photos</span>
                            </Button>
                        </div>
                    )}
                    <div className={styles.buttonContainer}>
                        <Button dark={false} onClick={handleSubmit} isDisabled={isDisabled}>
                            Next
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default UploadDocument;
