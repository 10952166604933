import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import validateEmail from '../../../utils/validateEmail';
import APIKit from '../../../utils/APIKit';
import handleError from '../../../utils/handleError';
import InputText from '../../global/inputs/InputText';
import TextArea from '../../global/inputs/TextArea';
import { ReactComponent as FacebookIcon } from '../../../assets/images/social-facebook-white.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/images/social-telegram-white.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/images/social-twitter-white.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/images/social-youtube-white.svg';
import styles from './ContactUs.module.scss';

const ContactUs = () => {
    const { t } = useTranslation();
    const [sendMessage, setSendMessage] = useState({ visible: false, error: false, message: '' });
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [captchaValue, setCaptchaValue] = useState<string | null>('');

    const [firstNameError, setFirstNameError] = useState<string>('');
    const [lastNameError, setLastNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [messageError, setMessageError] = useState<string>('');
    const [captchaError, setCaptchaError] = useState<string>('');

    const socialLinks = [
        { name: 'Twitter', icon: <TwitterIcon />, url: 'https://twitter.com/KubePay' },
        { name: 'Telegram', icon: <TelegramIcon />, url: 'https://t.me/kubecoin' },
        { name: 'Facebook', icon: <FacebookIcon />, url: 'https://www.facebook.com/kubecoin/' },
        { name: 'YouTube', icon: <YoutubeIcon />, url: 'https://www.youtube.com/c/KubeCoin' },
    ];

    const checkData = (data: any) => {
        const emailError = validateEmail(data.email);
        if (data.name.trim() === '') setFirstNameError('requiredField');
        if (data.message === '') setMessageError('requiredField');
        if (emailError.error.email !== '') setEmailError(emailError.error.email);
        if (captchaValue === '') setCaptchaError('checkCaptcha');

        return data.name !== '' && data.message !== '' && emailError.isValid && captchaValue !== '';
    };

    const sendData = (data: any) => {
        return APIKit.post('web/contact/', data)
            .then(() => setSendMessage({ visible: true, error: false, message: t('messageSend') }))
            .catch((error) => {
                const errorMessage = handleError(error);
                setSendMessage({ visible: true, error: true, message: errorMessage });
            });
    };

    const handleContactClick = (e: any) => {
        e.preventDefault();
        setFirstNameError('');
        setLastNameError('');
        setMessageError('');
        setEmailError('');
        setCaptchaError('');

        const fullName = `${firstName} ${lastName}`;

        const data = {
            name: fullName,
            email,
            message,
            recaptcha: captchaValue,
        };

        const isDataValid = checkData(data);

        if (isDataValid) {
            sendData(data);
            setFirstName('');
            setLastName('');
            setEmail('');
            setMessage('');
            setCaptchaValue('');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.sectionBackground} />
            <h1 className={styles.title}>{t('contactUs')}</h1>
            <p className={styles.subtitle}>{t('questionContactUs')}</p>
            <div className={styles.contentBox}>
                <div className={styles.formWrapper}>
                    <form>
                        <InputText
                            type="text"
                            label="firstName"
                            text="Jane"
                            value={firstName}
                            setValue={setFirstName}
                            errorMessage={firstNameError}
                        />
                        <InputText
                            type="text"
                            label="lastName"
                            text="Doe"
                            value={lastName}
                            setValue={setLastName}
                            errorMessage={lastNameError}
                        />
                        <InputText
                            type="email"
                            label="email"
                            text="jane.doe@example.com"
                            value={email}
                            setValue={setEmail}
                            errorMessage={emailError}
                        />
                        <TextArea
                            label="comments"
                            text="tellAboutProject"
                            value={message}
                            setValue={setMessage}
                            errorMessage={messageError}
                        />
                        <div className={styles.recaptchaContainer}>
                            <ReCAPTCHA
                                sitekey="6LemsE4gAAAAAC_e6zQjkgXsH28coZeBqYEOsiNW"
                                onChange={(value: any) => setCaptchaValue(value)}
                            />
                            <span className={styles.errorMessage}>{captchaError}</span>
                        </div>
                        {sendMessage.visible && sendMessage.error && (
                            <span className={styles.errorMessage}>{sendMessage.message}</span>
                        )}
                        {sendMessage.visible && !sendMessage.error && (
                            <span className={styles.correctMessage}>{sendMessage.message}</span>
                        )}
                        <button className={styles.btn} onClick={handleContactClick}>
                            {t('contact')}
                        </button>
                    </form>
                </div>
                <div className={styles.headQuartersContainer}>
                    <div className={styles.socialNetworksColumn}>
                        {socialLinks.map((socialNetwork) => (
                            <a href={socialNetwork.url} key={socialNetwork.name} target="_blank" rel="noreferrer">
                                {socialNetwork.icon}
                            </a>
                        ))}
                    </div>
                    <h2>{t('headquartersBCN')}</h2>
                    <p className={styles.headQuarterAddress}>
                        <span>Kube Ecosystem S.L.</span>
                        <span>Gran Via de les Corts Catalanes 415</span>
                        <span>08015 Barcelona, {t('spain')}</span>
                    </p>
                    {/* <h3>{t('investorRelatedInquiries')}</h3>
          <p className={styles.emailAddress}>investorinquiries@kubecoin.org</p>
          <h3>{t('becomePartner')}</h3>
          <p className={styles.emailAddress}>partners@kubecoin.org</p>
          <h3>{t('generalInquiries')}</h3>
          <p className={styles.emailAddress}>info@kubecoin.org</p> */}
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
