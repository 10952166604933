import { Trans, useTranslation } from 'react-i18next';
import styles from './IncludedFeatures.module.scss';
import worldIcon from '../../../../assets/images/world-icon.svg';
import transactionIcon from '../../../../assets/images/transaction-icon.svg';
import technicalSupportIcon from '../../../../assets/images/technicalSupport-icon.svg';
import securityIcon from '../../../../assets/images/security-icon.svg';

const IncludedFeatures = () => {
    const { t } = useTranslation();

    const features = [
        {
            id: 1,
            icon: worldIcon,
            title: 'internationalPayments',
            items: [
                { id: 1, text: 'acceptDigitalPayments' },
                { id: 2, text: 'forgetAboutCurrency' },
                { id: 3, text: 'globalSupport' },
                { id: 4, text: 'affiliateSupport' },
            ],
        },
        {
            id: 2,
            icon: transactionIcon,
            title: 'instantTransactions',
            items: [
                { id: 1, text: 'controlTransactions' },
                { id: 2, text: 'uniqueTransfers' },
                { id: 3, text: 'ownerOfMoney' },
                { id: 4, text: 'transparentTransfers' },
            ],
        },
        {
            id: 3,
            icon: technicalSupportIcon,
            title: 'technicalSupport',
            items: [
                { id: 1, text: 'supportTeam' },
                { id: 2, text: 'documentsOnUse' },
                { id: 3, text: 'completeKnowledgeBase' },
            ],
        },
        {
            id: 4,
            icon: securityIcon,
            title: 'maximumSecurity',
            items: [
                { id: 1, text: 'secureCollection' },
                { id: 2, text: 'transactionDataEncryption' },
                { id: 3, text: 'applicationAllWorld' },
            ],
        },
    ];

    return (
        <section className={styles.includedContainer}>
            <h2>{t('whatDoesInclude')}</h2>
            <p>
                <Trans i18nKey="whatDoesIncludeSubtitle">
                    Get many functions through the integration of <span className={styles.boldText}>KubePay</span>
                </Trans>
            </p>
            <div className={styles.flexBox}>
                {features.map((feature) => (
                    <div key={feature.id} className={styles.featureContainer}>
                        <img src={feature.icon} alt={feature.title} className={styles.icon} />
                        <h3>{t(`${feature.title}`)}</h3>
                        <ul>
                            {feature.items.map((item) => (
                                <li key={item.id}>{t(`${item.text}`)}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default IncludedFeatures;
