import { useTranslation } from 'react-i18next';
import styles from './InfoBox.module.scss';
import toolsIcon from '../../../../assets/images/tools-icon.svg';
import globeIcon from '../../../../assets/images/globe-icon.svg';
import lightningIcon from '../../../../assets/images/lightning-icon.svg';
import Button from '../button/Button';

const InfoBox = () => {
    const { t } = useTranslation();
    const features = [
        { id: 1, icon: lightningIcon, text: 'acceptDigitalCurrency' },
        { id: 2, icon: globeIcon, text: 'developBusiness' },
        { id: 3, icon: toolsIcon, text: 'noHiddenCosts' },
    ];

    return (
        <section className={styles.infoBox}>
            <div className={styles.upperBox}>
                <h2>{t('allInOne')}</h2>
                <p className={styles.infoText}>{t('allInOneParag')}</p>
                <ul className={styles.featureList}>
                    {features.map((feature) => (
                        <li key={feature.id}>
                            <img src={feature.icon} alt={feature.text} />
                            <span>{t(`${feature.text}`)}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.lowerBox}>
                <div className={styles.innerLowerContainer}>
                    <h2>{t('personalized')}</h2>
                    <h3>{t('personalizedSubtitle')}</h3>
                    <p>{t('personalizedParag')}</p>
                    <Button />
                </div>
            </div>
        </section>
    );
};

export default InfoBox;
