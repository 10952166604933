import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { setProofOfAddress } from '../../../../redux/kycSlice';
import styles from './ProofOfAddress.module.scss';
import Button from '../commons/buttons/Button';
import { routes } from '../../../../utils/routes';
import UploadDocument from '../commons/inputFile/InputFile';
import MessageBox from '../commons/messageBox/MessageBox';
import UploadSucceed from '../commons/uploadSucceed/UploadSucceed';
import redoActionIcon from '../../../../assets/images/redoAction-icon.svg';
import StepIndicator from '../commons/stepIndicator/StepIndicator';

const ProofOfAddress = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const proofOfAddress = useSelector((state: RootState) => state.kyc.proofOfAddress);
    const [document, setDocument] = useState<any>(new File([], ''));
    const [errors, setErrors] = useState<string>('');
    const [hasUploaded, setHasUploaded] = useState<boolean>(false);
    const [reUpload, setReUpload] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (errors === '') {
            dispatch(setProofOfAddress(document));
            navigate(routes.kycFinishVerification);
        }
    };

    const handleBack = () => {
        navigate(routes.kycUploadDocument);
    };

    const handleReupload = (e: any) => {
        e.preventDefault();
        setHasUploaded(false);
        setDocument(new File([], ''));
        setReUpload(false);
        setIsDisabled(true);
        dispatch(setProofOfAddress(new File([], '')));
    };

    useEffect(() => {
        if ('name' in proofOfAddress && proofOfAddress.name !== '') {
            setDocument(proofOfAddress);
            setHasUploaded(true);
            setReUpload(true);
        } else {
            setHasUploaded(false);
        }
    }, [proofOfAddress]);

    useEffect(() => {
        if (hasUploaded) setIsDisabled(false);
    }, [hasUploaded]);

    return (
        <section className={styles.stepContainer}>
            <div className={styles.innerStepContainer}>
                <h3 className={styles.pageTitle}>Proof of Address</h3>
                <StepIndicator step={3} handleClick={handleBack} />
                <div className={styles.paragContainer}>
                    <p>
                        Please provide proof of address dated within the last 3 months to confirm your address of
                        residence.
                    </p>
                    <p>
                        Proof of address includes residence permits, utility bills, credit card or bank statements,
                        lease agreements, mortgage statements, car/home insurance policies, or letters issued by public
                        authorities or educational institutions.
                    </p>
                </div>
                <form noValidate>
                    {hasUploaded && <UploadSucceed />}
                    {!hasUploaded && (
                        <UploadDocument
                            setValue={setDocument}
                            name="upload1"
                            setErrors={setErrors}
                            setHasUploaded={setHasUploaded}
                            setReUpload={setReUpload}
                        >
                            Upload your document
                        </UploadDocument>
                    )}
                    <div className={styles.messageContainer}>
                        {errors !== '' && <MessageBox text={errors} type="error" />}
                    </div>
                    {reUpload && (
                        <div className={styles.buttonContainer}>
                            <Button dark onClick={handleReupload} className={styles.reuploadBtn}>
                                <img src={redoActionIcon} alt="re do action icon" />
                                <span>Reupload photos</span>
                            </Button>
                        </div>
                    )}
                    <div className={styles.buttonContainer}>
                        <Button dark={false} onClick={handleSubmit} isDisabled={isDisabled}>
                            Next
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default ProofOfAddress;
