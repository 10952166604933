import { Link } from 'react-router-dom';
import routes from '../../../utils/routes';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.scss';
import { ReactComponent as Logo } from '../../../assets/images/logo-white.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/images/social-facebook-white.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/images/social-telegram-white.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/images/social-twitter-white.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/images/social-youtube-white.svg';
import LanguageSelector from '../languageSelector/LanguageSelector';

const Footer = () => {
    const { t } = useTranslation();

    const socialLinks = [
        { name: 'Twitter', icon: <TwitterIcon />, url: 'https://twitter.com/KubePay' },
        { name: 'Telegram', icon: <TelegramIcon />, url: 'https://t.me/kubecoin' },
        { name: 'Facebook', icon: <FacebookIcon />, url: 'https://www.facebook.com/kubecoin/' },
        { name: 'Youtube', icon: <YoutubeIcon />, url: 'https://www.youtube.com/c/KubeCoin' },
    ];

    const websiteLinks = [
        { title: 'home', path: routes.home },
        { title: 'products', path: routes.products },
        { title: 'pricing', path: routes.pricing },
        { title: 'contactSales', path: routes.contactSales },
        { title: 'faqs', path: routes.faqs },
        { title: 'contactUs', path: routes.contact },
    ];

    const documentationLinks = [
        {
            title: 'listing',
            path: 'https://docs.google.com/forms/d/e/1FAIpQLScb6biwza0RdAYL80V-6V9K227gqwpXmZmRoUGLld6pczDdgg/viewform',
        },
        { title: 'terms', path: routes.terms },
        { title: 'privacyPolicy', path: routes.privacy },
    ];

    const kubeEcosystemLinks = [
        { title: 'Flykube', path: 'https://flykube.com/' },
        { title: 'KubeCoin', path: 'https://kubecoin.org/' },
        { title: 'Eatkube', path: 'https://eatkube.com/' },
        { title: 'Royal Hawk Club', path: 'https://royalhawkclub.com/' },
        { title: 'KubeIn', path: 'https://kubein.vc/' },
    ];

    return (
        <footer>
            <div className={styles.sectionsContainer}>
                <div className={`${styles.column} ${styles.socialNetworksColumn}`}>
                    <h3 className={`${styles.listTitle} ${styles.hideMobile}`}>{t('followUs')}</h3>
                    {socialLinks.map((socialNetwork) => (
                        <a href={socialNetwork.url} key={socialNetwork.name} target="_blank" rel="noreferrer">
                            {socialNetwork.icon}
                        </a>
                    ))}
                </div>

                <div className={styles.logoColumn}>
                    <Logo className={styles.logoImg} />
                </div>

                <nav className={`${styles.column} ${styles.ecosystemColumn}`}>
                    <h3 className={styles.listTitle}>KUBE ECOSYSTEM</h3>
                    <ul className={styles.navLinks}>
                        {kubeEcosystemLinks.map((link) => (
                            <li key={link.title} className={styles.navLink}>
                                <a href={link.path} target="_blank" rel="noreferrer">
                                    {t(`${link.title}`)}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>

                <nav className={`${styles.column} ${styles.websiteColumn}`}>
                    <h3 className={styles.listTitle}>WEBSITE</h3>
                    <ul className={styles.navLinks}>
                        {websiteLinks.map((link) => (
                            <li key={link.title} className={styles.navLink}>
                                <Link to={link.path}>{t(`${link.title}`)}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>

                <nav className={`${styles.column} ${styles.documentationColumn}`}>
                    <h3 className={styles.listTitle}>DOCUMENTATION</h3>
                    <ul className={styles.navLinks}>
                        {documentationLinks.map((link) => {
                            const isListing = link.title === 'listing';
                            return (
                                <li key={link.title} className={styles.navLink}>
                                    {isListing ? (
                                        <a href={link.path} target="_blank" rel="noreferrer">
                                            {t(`${link.title}`)}
                                        </a>
                                    ) : (
                                        <Link to={link.path}>{t(`${link.title}`)}</Link>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </nav>

                {/*  <div className={styles.languageSelectorContainer}>
                    <LanguageSelector />
                </div> */}

                <p className={styles.copyright}>
                    2022 KUBE ECOSYSTEM SL © {t('allRightsReserved')}
                    <span>Gran Via 415, 08015, Barcelona, {t('spain')}</span>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
