import { useEffect, useState } from 'react';

const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const onWindowResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => window.removeEventListener('resize', onWindowResize);
    }, []);

    return { width };
};

export default useResize;
