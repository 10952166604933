import { useTranslation } from 'react-i18next';
import TermsEN from './languages/TermsEN';
import TermsES from './languages/TermsES';
import styles from './common.module.scss';
import LanguageSelector from '../../global/languageSelector/LanguageSelector';

const TermsOfUse = () => {
    const { i18n } = useTranslation();
    const currentLang = i18n.language;
    console.log({ currentLang });

    const getContainer = () => {
        if (currentLang === 'en') return <TermsEN />;
        if (currentLang === 'es') return <TermsES />;
        return <TermsEN />;
    };

    return (
        <div className={styles.pageContainer}>
            <div className={styles.pageWrapper}>
                <div className={styles.languageSelectorContainer}>
                    <LanguageSelector from="terms" />
                </div>
                {getContainer()}
            </div>
        </div>
    );
};

export default TermsOfUse;
