import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '../../../../utils/routes';
import styles from './Button.module.scss';

const Button = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = () => navigate(routes.contactSales);

    return (
        <button className={styles.btn} onClick={handleClick}>
            <span>{t('contactSales')}</span>
        </button>
    );
};

export default Button;
