import { useTranslation } from 'react-i18next';
import blockchainIcon from '../../../../assets/images/blockchain.svg';
import decentralizedIcon from '../../../../assets/images/decentralized.svg';
import saveMoneyIcon from '../../../../assets/images/save-money.svg';
import newEconomyIcon from '../../../../assets/images/new-economy.svg';
import styles from './WhyKubePay.module.scss';

const WhyKubePay = () => {
    const { t } = useTranslation();

    return (
        <section>
            <div className={styles.contentWrapper}>
                <h2 className={styles.sectionTitle}>{t('whyKubePay')}</h2>
                <h3 className={styles.sectionSubtitle}>{t('whyKubePaySubtitle')}</h3>
                <ul className={styles.itemsContainer}>
                    <li className={styles.listItem}>
                        <div className={styles.itemHeader}>
                            <img src={blockchainIcon} alt="" />
                            <h4>{t('whyReason1Title')}</h4>
                        </div>
                        <div className={styles.itemDescription}>
                            <p>{t('whyReason1Desc')}</p>
                        </div>
                    </li>
                    <li className={styles.listItem}>
                        <div className={styles.itemHeader}>
                            <img src={decentralizedIcon} alt="" />
                            <h4>{t('whyReason2Title')}</h4>
                        </div>
                        <div className={styles.itemDescription}>
                            <p>{t('whyReason2Desc')}</p>
                        </div>
                    </li>
                    <li className={styles.listItem}>
                        <div className={styles.itemHeader}>
                            <img src={saveMoneyIcon} alt="" />
                            <h4>{t('whyReason3Title')}</h4>
                        </div>
                        <div className={styles.itemDescription}>
                            <p>{t('whyReason3Desc')}</p>
                        </div>
                    </li>
                    <li className={styles.listItem}>
                        <div className={styles.itemHeader}>
                            <img src={newEconomyIcon} alt="" />
                            <h4>{t('whyReason4Title')}</h4>
                        </div>
                        <div className={styles.itemDescription}>
                            <p>{t('whyReason4Desc')}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default WhyKubePay;
