const PrivacyEN = () => (
    <>
        <h1>Privacy Policy</h1>
        <h2>Data Controller</h2>
        <p>
            The Data Controller is Kube Ecosystem, S.L., with TIN B67040998 (hereinafter referred as the Holder) and
            registered offices at Gran Via 415, 08015, Barcelona, Spain, holder of the application KubePay (hereinafter
            referred as KubePay)
        </p>
        <p>
            This Privacy Policy regulates the collection and processing of personal data which are provided by the Users
            when they access, browser or use the functionalities of the App.
        </p>
        <h2>Data collection, purposes, legal basis and storage periods:</h2>
        <ul>
            <li>
                <span>a. Contact:</span> The User may contact the Holder through the form and addresses established for
                that purpose on KubePay. Identification data, as well as the motive, subject or question, shall be
                provided. The Holder will use this data to process the enquiry and to contact the User.
                <p>The lawfulness of this data processing is the consent given by the User.</p>
                <p>
                    The data storage period for this purpose will be of one year, unless different periods are
                    applicable.
                </p>
            </li>
            <li>
                <span>b. Registration:</span> By registering as a User on Kubepay, it will be necessary for the User to
                remember the recovery phrase indicated, in order to process the registration in the App and create his
                Wallet, allowing access to the functionalities of his personal account.
                <p>
                    The lawfulness of this data processing is based on the execution of the contract or license of use.
                </p>
                <p>
                    Your data will be kept as long as you are part of the community and are registered, unless other
                    deadlines apply.
                </p>
            </li>
            <li>
                <span>c. Acquisition of the KubeCoin token:</span> The User on KubePay can acquire, sell or swap the
                number of KubeCoin tokens of his choice. For that purpose, the User must provide the necessary data to
                process the management and correct allocation of the purchased tokens, including his Wallet address.
                <p>The lawfulness of this data processing is the execution of the contract.</p>
                <p>
                    Your data will be kept the appropriate period to comply with contractual obligations and, in any
                    case, up to five years for tax purposes and to cover possible liabilities, or ten years for money
                    laundering, if it was applicable and the amount acquired exceeds one thousand euros, unless other
                    periods are applicable.
                </p>
            </li>

            <li>
                <span>d. Digital currency transactions:</span> Through Kubepay, the User can make or receive payments
                through digital currencies, whether KUBEs, ADA or other types of digital currencies. In order to do so,
                you must provide the information necessary to process the transaction, including your Wallet address.
                <p>The lawfulness of this data processing is the execution of the contract.</p>
                <p>
                    Your data will be kept the appropriate period to comply with contractual obligations and, in any
                    case, up to five years for tax purposes and to cover possible liabilities, or ten years for money
                    laundering, if it was applicable and the amount acquired exceeds one thousand euros, unless other
                    periods are applicable.
                </p>
            </li>
            <li>
                <span>e. Purchase of products through the Marketplace:</span> In order to purchase the products
                available in the Marketplace offered by Kubepay, the User must provide the necessary data to process the
                purchase and order (including shipment through transport companies).
                <p>The lawfulness of this data processing is the contractual relationship with the User.</p>
                <p>
                    Your data will be kept the appropriate period to comply with contractual obligations and, in any
                    case, up to five years for tax purposes and to cover possible liabilities.
                </p>
            </li>
            <li>
                <span>f. Claiming:</span> The User who, prior to using Kubepay, have stored KUBEs on kubecoin.org, will
                be able to receive them in Kubepay through the "Claim your KUBEs" functionality, providing the necessary
                data for management.
                <p>The lawfulness of this data processing is the contractual relationship with the User.</p>
                <p>
                    Your data will be kept the appropriate period to comply with contractual obligations and, in any
                    case, up to five years for tax purposes and to cover possible liabilities.
                </p>
            </li>
            <li>
                <span>g. NFT Storage:</span> The User will be able to store, view and sell their NFTs on Kubepay and its
                Marketplace. For that purpose, you must provide the information necessary to manage the storage or sale,
                including your Wallet address and the code of the NFT.
                <p>The lawfulness of this data processing is the execution of the contract.</p>
                <p>
                    Your data will be kept the appropriate period to comply with contractual obligations and, in any
                    case, up to five years for tax purposes and to cover possible liabilities, or ten years for money
                    laundering, if it was applicable and the amount acquired exceeds one thousand euros, unless other
                    periods are applicable.
                </p>
            </li>
        </ul>
        <h2>Exercising of Data Protection Rights</h2>
        <p>
            Furthermore, the User may revoke his or her consent for the data processing, by exercising the rights of
            access, rectification, erasure, objection, restriction of processing and data portability by contacting the
            Holder through an email to privacy@flykube.com or by indicating it to the address above-mentioned, pointing
            “Data Protection” as motive. In addition, we may ask you for evidence of your identity if we have doubts
            about it, in order to prevent unauthorized access to your data.
        </p>
        <p>
            The User may communicate any modification by writing or request the cancellation, by indicating such event
            to any of the above-mentioned addresses.
        </p>
        <p>
            At any time, the User may lodge a complaint with the Spanish Data Protection Agency (AEPD), in order to
            enforce his or her rights.
        </p>
        <h2>Modifications</h2>
        <p>
            The Holder reserves the right to modify this Privacy Policy at any time, in respecting of the legislation in
            force in terms of data protection and following advance notice to the data subjects.
        </p>
        <h2>Language</h2>
        <p>
            The applicable language of this Privacy Policy is Spanish. The Holder may offer the Users the translation of
            this document in several languages for their convenience only, to help them to understand it, but the
            official text is the Spanish version.{' '}
        </p>
        <p>
            In case of contradictions between the Spanish version and its translations, the Spanish version shall
            prevail.
        </p>
    </>
);

export default PrivacyEN;
