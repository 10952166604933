import { useTranslation } from 'react-i18next';
import sectionImg from '../../../../assets/images/home-whatsKubepay-image.png';
import styles from './WhatIsKubePay.module.scss';

const WhatIsKubePay = () => {
    const { t } = useTranslation();

    return (
        <section>
            <div className={styles.contentWrapper}>
                <div className={styles.imageContainer}>
                    <img src={sectionImg} className={styles.image} alt="" />
                </div>
                <div className={styles.textContainer}>
                    <h2>{t('whatsKubePay')}</h2>
                    <p>{t('whatsKubePayParag1')}</p>
                    <p>{t('whatsKubePayParag2')}</p>
                    <p>{t('whatsKubePayParag3')}</p>
                </div>
            </div>
        </section>
    );
};

export default WhatIsKubePay;
