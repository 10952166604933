import greenCheckedIcon from '../../../../assets/images/greenChecked-icon.svg';
import styles from './Congrats.module.scss';

const FourthStep = () => {
    return (
        <section className={styles.pageContainer}>
            <div className={styles.innerContainer}>
                <img src={greenCheckedIcon} className={styles.checkIcon} alt="" />
                <h1 className={styles.pageTitle}>Congratulations!</h1>
                <p className={styles.pageMessage}>Your KYC process is being reviewed</p>
            </div>
        </section>
    );
};

export default FourthStep;
