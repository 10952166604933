import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/logo.svg';
import logoWhite from '../../../assets/images/logo-white.png';
import menuIcon from '../../../assets/images/menu-burguer.svg';
import closeIcon from '../../../assets/images/close.svg';
import routes from '../../../utils/routes';
import styles from './Header.module.scss';
import useResize from '../../../hooks/useResize';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { width } = useResize();
    const isMobile = width < 1020;
    const isHomePage = pathname === routes.home;
    const { t } = useTranslation();

    const navLinks = [
        { name: 'home', path: routes.home },
        { name: 'products', path: routes.products },
        { name: 'pricing', path: routes.pricing },
        {
            name: 'listing',
            path: 'https://docs.google.com/forms/d/e/1FAIpQLScb6biwza0RdAYL80V-6V9K227gqwpXmZmRoUGLld6pczDdgg/viewform',
        },
        { name: 'contactUs', path: routes.contact },
        { name: 'faqs', path: routes.faqs },
    ];

    const goHome = () => {
        navigate(routes.home);
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    const getLogo = () => {
        if (isHomePage && !isMobile) return logoWhite;
        return logo;
    };

    return (
        <header className={`${styles.header} ${isHomePage && styles.headerHome}`}>
            <div className={styles.headerWrapper}>
                <Link to={routes.home} onClick={goHome} className={styles.logoWrapper}>
                    <img src={getLogo()} className={styles.logo} alt="" />
                </Link>
                <div className={`${styles.headerMenuWrapper} ${showMenu && styles.menuActive}`}>
                    <nav className={styles.navContainer}>
                        <ul className={styles.linksContainer}>
                            {navLinks.map((navLink) => {
                                const isListing = navLink.name === 'listing';
                                const isActive = pathname === navLink.path;
                                const linkClassName = isActive ? `${styles.link} ${styles.linkActive}` : styles.link;

                                return (
                                    <li key={navLink.name} className={linkClassName}>
                                        {isListing ? (
                                            <a href={navLink.path} onClick={closeMenu} target="_blank" rel="noreferrer">
                                                {t(`${navLink.name}`)}
                                            </a>
                                        ) : (
                                            <Link to={navLink.path} onClick={closeMenu}>
                                                {t(`${navLink.name}`)}
                                            </Link>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                    <Link to={routes.contactSales} className={styles.contactButton} onClick={closeMenu}>
                        <span>Contact Sales</span>
                    </Link>
                </div>
                <button className={`${styles.menuButton} ${showMenu && styles.menuButtonActive} `} onClick={toggleMenu}>
                    <img src={showMenu ? closeIcon : menuIcon} alt="menu" />
                </button>
            </div>
        </header>
    );
};

export default Header;
