import { useTranslation } from 'react-i18next';
import styles from './Products.module.scss';
import mobilesImg from '../../../assets/images/mobiles-img.png';
import swapMobileImg from '../../../assets/images/swap-mobile.png';
import circleImg from '../../../assets/images/KP_Cercle-img.png';
import kPoolIcon from '../../../assets/images/kpool.png';
import { Link } from 'react-router-dom';
import routes from '../../../utils/routes';

const Products = () => {
    const { t } = useTranslation();

    return (
        <>
            <h1 className={styles.title}>{t('products')}</h1>
            <section className={styles.sectionContainer}>
                <div className={styles.sectionColumns}>
                    <div className={styles.sectionColumn}>
                        <div className={styles.walletTextContainer}>
                            <h2 className={`${styles.subtitle} ${styles.colouredSubtitle}`}>{t('kubePayWallet')}</h2>
                            <p>{t('kubePayWalletParag1')}</p>
                            <p>{t('kubePayWalletParag2')}</p>
                            <p>{t('kubePayWalletParag3')}</p>
                            <p>{t('kubePayWalletParag4')}</p>
                        </div>
                    </div>
                    <div className={styles.sectionColumn}>
                        <div className={`${styles.imageWrapper} ${styles.mobilesImageWrapper}`}>
                            <img src={mobilesImg} alt="Two mobiles" />
                        </div>
                    </div>
                </div>
            </section>
            <section className={`${styles.sectionContainer} ${styles.overlap}`}>
                <div className={styles.sectionBackground} />
                <div className={styles.sectionColumns}>
                    <div className={styles.sectionColumn}>
                        <div className={styles.paymentTextContainer}>
                            <h2 className={styles.subtitle}>{t('paymentsGetaway')}</h2>
                            <p>{t('paymentsGetawayParag1')}</p>
                            <p>{t('paymentsGetawayParag2')}</p>
                            <Link to={routes.contactSales} className={styles.contactSalesButton}>
                                {t('contactSales')}
                            </Link>
                        </div>
                    </div>
                    <div className={`${styles.sectionColumn} ${styles.desktopInvertedOrder}`}>
                        <div className={`${styles.imageWrapper} ${styles.paymentImageWrapper}`}>
                            <img src={circleImg} alt="Two mobiles" />
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.sectionContainer}>
                <div className={styles.sectionColumns}>
                    <div className={styles.sectionColumn}>
                        <div className={styles.swapTextContainer}>
                            <h2 className={`${styles.subtitle} ${styles.colouredSubtitle}`}>{t('kubeSwap')}</h2>
                            <p>{t('kubeSwapParag1')}</p>
                            <div className={styles.kPoolContainer}>
                                <div className={styles.kPoolTitle}>
                                    <img src={kPoolIcon} alt="" />
                                    <span>{t('kPool')}</span>
                                </div>
                                <p>{t('kPoolParag1')}</p>
                                <p>
                                    {t('kPoolParag2')} <span className={styles.bold}>KPOOL</span>
                                </p>
                                <p>
                                    {t('kPoolParag3')} <span className={styles.bold}>4,89%</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.sectionColumn}>
                        <img className={styles.swapImage} src={swapMobileImg} alt="Kube Swap" />
                    </div>
                </div>
            </section>
            <div className={styles.blueRectangle} />
        </>
    );
};

export default Products;
