const validateEmail = (email: any) => {
    const error = { email: '' };
    let validEmail = false;
    const emailRegEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === '') error.email = 'requiredField';
    else if (!emailRegEx.test(String(email).toLowerCase())) error.email = 'wrongEmail';
    else validEmail = true;

    return {
        error,
        isValid: validEmail,
    };
};

export default validateEmail;

