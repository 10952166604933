import React from 'react';
import styles from './Button.module.scss';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    dark?: boolean;
    isDisabled?: boolean;
}

interface IPropsText extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isDisabled?: boolean;
}

interface IPropsDocType extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Button = ({ children, dark, className, onClick, isDisabled }: IProps) => {
    const disabledValue = isDisabled !== undefined ? isDisabled : false;

    return (
        <button
            className={`${className} btn ${dark ? styles.blueBtn : styles.redBtn}`}
            onClick={onClick}
            disabled={disabledValue}
        >
            {children}
        </button>
    );
};

export default Button;

export const TextButton = ({ children, onClick, className, isDisabled }: IPropsText) => (
    <button
        className={className}
        onClick={onClick}
        disabled={isDisabled}
    >
        {children}
    </button>
);

export const DocTypeButton = ({ children, className, onClick }: IPropsDocType) => {
    return (
        <button
            className={className}
            onClick={onClick}

        >
            {children}
        </button>
    );
};
