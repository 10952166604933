const TermsEN = () => (
    <>
        <h1>General Conditions and License of Use </h1>
        <h2>1. Identification of the parties</h2>
        <p>
            1.1. The General Terms and Conditions and License of Use contained herein are subscribed, on the one hand by
            KUBE ECOSYSTEM S.L. with NIF B67040998 (hereinafter the Owner) and address at Gran Via de les Corts
            Catalanes 415, 08015, Barcelona, Spain, as the owner of the Kubepay Wallet application (hereinafter the App
            or Kubepay), and, on the other hand, by the User, understood as the natural person of legal age who freely
            and voluntarily downloads, installs or accesses Kubepay, regardless of whether or not they make use of the
            services and tools offered therein at any given time.
        </p>
        <p>
            1.2. The User, by accessing, downloading or installing Kubepay, accepts these Conditions insofar as they may
            be applicable to any Internet User who downloads the App. The Owner recommends a careful reading of the
            present document, as well as the periodic review of its contents.
        </p>
        <h2>2. Minimum age</h2>
        <p>
            Spanish legislation does not prohibit the exchange of fiat currency for digital assets, or vice versa, to
            minors under 18 years of age. However, it is not advisable for minors to access, browse and use the services
            offered by Kubepay due to the characteristics of digital assets.
        </p>
        <h2>3. Kubepay</h2>
        <p>
            3.1. The Application is an App made available to Users of mobile devices (both mobile phones and tablets)
            with an iOS or Android operating system, through the Apple application shop, the App Store, or Google Play,
            with the purpose of allowing individuals and legal entities to easily pay and receive payments in digital
            currencies and, in particular, for Users to send or receive the Cardano digital currency (ADA) and Kubecoin
            tokens. It is not required to be registered and subscribed to the services offered as it is an anonymous and
            decentralized wallet. In addition, Users will be able to view past and present prices of tokens, valued in
            both digital currencies and fiat currencies.
        </p>
        <p>
            3.2. The download and installation of Kubepay are free of charge, notwithstanding the fact that its use is
            subject to payment, in terms of payment of fees for transactions or the sale of products and digital assets.
        </p>
        <p>
            3.3. Kubepay also offers means of contact with the Owner, for the purpose of sending doubts, questions,
            complaints or suggestions by sending an email to info@kubepay.io.
        </p>
        <p>
            3.4. The Kubepay App is designed to be used on devices with Internet access. However, the App does not
            include access to the Internet or mobile phone networks, so the User must pay the rates of the corresponding
            Internet access provider or mobile operator. Please check the access and pricing conditions of your provider
            before using the App.
        </p>
        <h2>4. Obligations of the User</h2>
        <ul>
            <li>
                a. To make diligent, correct and lawful use of Kubepay, respecting current legislation (and especially
                that relating to data protection and intellectual and industrial property), morality and good customs,
                as well as public order.
            </li>
            <li>
                b. Periodically review the present General Conditions and License of Use, or any other conditions that
                may be applicable, verifying the changes that, if applicable, they may have undergone.
            </li>
            <li>
                c. Check the notifications that, where appropriate, are sent by the Owner, as they may include important
                information.
            </li>
            <li>
                d. Not to use Kubepay for commercial purposes; for example, collecting information or content to provide
                other services that may be in clear competition with the Holder.
            </li>
            <li>
                e. Not to modify or attempt to modify Kubepay in any way or take any action or use any means to simulate
                its appearance or functions.
            </li>
            <li>
                f. Refrain from carrying out any action that involves the introduction of computer viruses, worms,
                Trojans or any other kind of malicious code intended to interrupt, destroy or limit the functionality of
                Kubepay.
            </li>
            <li>
                g. Not to use reverse engineering techniques and/or decrypt, decompile or use any other system aimed at
                discovering the source code of Kubepay or any element subject to copyright or underlying intellectual
                property.
            </li>
            <li>
                h. Not to damage, disable, overburden, or impair the service (or the network(s) connected to the
                service), or interfere with the use and enjoyment of the service.
            </li>
            <li>
                i. In any case, not to perform any acts that may infringe rights or interests of the Owner or third
                parties such as, for example, intellectual or industrial property rights (patents, trademarks,
                copyrights, trade secrets...).
            </li>
            <li>j. Respect the provisions of these legal texts.</li>
        </ul>
        <h2>5. Operation of Kubepay</h2>
        <p>
            Once the User creates a Wallet in Kubepay, he/she will be able to make use of the following functionalities:
        </p>
        <ul>
            <li>
                a. Purchase services and/or products from the Kube family (Flykube and EatKube) or from the Partners of
                Kube Ecosystem, S.L..
            </li>
            <li>
                b. Buy and sell NFTs in the Marketplace incorporated in Kubepay. You can also use Kubepay as storage for
                their NFTs.
            </li>
            <li>c. Staking their KUBEs to get rewards.</li>
            <li>
                d. Pay in establishments using Kubepay to purchase products, sending KUBEs to other users or
                establishments.
            </li>
            <li>
                e. Exchange KUBEs with Cardano digital currency (ADA), with Kubepay charging a commission for the
                exchange.
            </li>
            <li>
                f. Make instant wireless payments between B2C users or from user to supplier/merchant via Kubepay's
                Payment Gateway. This entails the payment of fees that vary over time and are different depending on the
                User.
            </li>
        </ul>
        <h2>6. Particular Conditions of Registration</h2>
        <p>
            These Particular Conditions of Registration are subscribed to by Kubepay on the one hand, and on the other
            hand, by the User who has completed the steps indicated in the App in order to create his/her Wallet,
            together with the acceptance of these Particular Conditions.
        </p>
        <p>6.1. Requirements and procedure for creating a Wallet</p>
        <p>
            In order to generate a Wallet, the User must establish the recovery phrase, which is composed of 12 random
            words. After that, he/she must complete all the steps indicated, including reading the information relating
            to the Privacy Policy and these Conditions.
        </p>
        <p>Once this has been done, the User will become a Registered User.</p>
        <p>
            If the Registered User does not remember his/her recovery phrase, he/she should be aware that he/she will
            not be able to access his/her Wallet, as it cannot be reset.
        </p>
        <p>6.2. Kubepay</p>
        <p>
            Creating a Wallet on Kubepay allows Registered Users to send or receive Kubecoin tokens, the Cardano digital
            currency (ADA) and other digital currencies. In addition, Registered Users will be able to view past and
            present token prices, valued in both digital currencies and fiat currency.
        </p>
        <p>6.3. Obligations of the Registered User</p>
        <p>The Registered User has the following obligations:</p>
        <ul>
            <li>
                a. Keep and securely store the Wallet address, the recovery phrase and the private key that gives access
                to Kubepay's functionalities, as well as any other code needed for the correct use of Kubepay.
            </li>
            <li>
                b. Not to share the Wallet address, the recovery phrase and the private key, as well as any other codes
                necessary for the operation of Kubepay, with third parties.
            </li>
        </ul>
        <h2>7. Intellectual Property and License of Use</h2>
        <p>
            7.1. By virtue of the acceptance of these Conditions and provided that the User complies with all its
            obligations under these Conditions or any other applicable conditions, the Owner grants the User a
            non-exclusive right to use the App, as it is offered from time to time, without the possibility of
            assignment or sublicense, worldwide and for as long as the App remains installed on the User's device, by
            virtue of which:
        </p>
        <ul>
            <li>
                a. The User is authorized to download and install the App on his/her mobile device without limitation,
                although measures may be implemented to limit the number of simultaneous connections from a single
                location or IP address at the same time.
            </li>
            <li>
                b. The User is also authorized to access and use the App, as long as it is active and available, for
                particular and private purposes.
            </li>
        </ul>
        <p>
            7.2. Under no circumstances is the User authorized by virtue of this license to use the App for commercial
            purposes and/or purposes other than those provided for in the previous section.
        </p>
        <p>
            7.3. This license of use does not imply the transfer of any intellectual or industrial property right over
            the App or over any content present in it, with the intellectual property rights over the App and all the
            elements that make it up (including the programming, design, applications, graphics, codes, text or images
            present therein, as well as the published content and the technology associated with it) being the exclusive
            property of the Owner or the Owner having sufficient rights or authorizations for its use. Consequently, the
            reproduction, distribution, public communication, the making available or the transformation of Kubepay or
            any element integrated in the App is prohibited.
        </p>
        <p>
            7.4. Similarly, the domain name, brands, trademarks, trade names, and in general, any distinctive sign
            relating to Kubepay is owned by the Owner or the Owner has the necessary licenses to use them.
        </p>
        <p>
            7.5. If you detect any infringement of intellectual and/or industrial property rights in Kubepay, we ask you
            to inform us as soon as possible by writing to the e-mail address info@kubein.io.
        </p>
        <h2>8. Links to third parties</h2>
        <p>
            8.1. It is possible that Kubepay may include links to pages or websites of third parties. The Owner assumes
            no responsibility for them or for their content or proper functioning, nor for the consequences arising from
            access to them.
        </p>
        <p>
            8.2. With respect to the links published by the Owner, the User acknowledges and accepts that such links
            lead to external and alien sites, both to Kubepay and to the Owner, and that KubePay and the Owner do not
            approve or review the functions, advertising or, in general, the content included in third party pages, even
            when they are linked from Kubepay; Therefore, Kubepay cannot guarantee that there are no computer threats,
            viruses or malware, or that they do not contain illicit or inappropriate content or other links that, in
            turn, lead to sites with one or more of the aforementioned characteristics.
        </p>
        <p>
            8.3. The inclusion of links to third party websites on Kubepay and its services does not imply approval or
            acceptance of the linked website by the Owner. If the User decides to leave the official Kubepay pages and
            access third party websites, he/she does so at his/her own risk. Kubepay recommends reading the terms of use
            and privacy policies of third party websites.
        </p>
        <h2>9. Exclusion of liability</h2>
        <p>
            9.1. The Owner works to ensure that the services and functionalities of Kubepay are always available.
            However, Kubepay will be displayed "as is", depending on availability and concurrent limitations at any
            given time.
        </p>
        <p>
            9.2. Despite the continuous effort made by the Owner to protect the systems and contents included in
            Kubepay, which it uses according to the usual Internet security standards, it is not possible to offer full
            guarantees in relation to the intrusions or loss of information that may occur. Likewise, the absence of
            viruses or other harmful elements in Kubepay or in third party sites that may cause alterations in the
            User's computer system, both software and hardware, cannot be guaranteed. For this reason, the User assumes
            and understands that there are situations that may be beyond the control of the Owner.
        </p>
        <p>
            9.3. The Owner disclaims any liability for improper access to content as a result of the creation of a
            Wallet.
        </p>
        <p>
            9.4. The Owner disclaims all liability arising from misuse of the App by the Kubepay User, as well as for
            breach of the obligations or commitments assumed under these Conditions or any other that may be applicable.
        </p>
        <p>
            9.5. In particular, the Owner shall not be liable for the loss of digital currencies or tokens, in the event
            that the User has lost or forgotten his/her Wallet address, private key or recovery phrase, as well as any
            other codes that may apply, or in the event that they have been stolen. In this case, the User acknowledges
            that there is a possibility that he/she may not be able to recover his/her KUBEs or any other digital
            currency, as such data is only within the User's knowledge.
        </p>
        <p>
            9.6. In general, neither the Owner nor its collaborators shall be liable in the event of loss of profit or
            consequential damage for any reason whatsoever.
        </p>
        <p>
            9.7. Kubepay shall in no event be liable for how the App is used, nor for what is shared on any network or
            by any means through Kubepay. Nor shall it be liable if the User breaches any obligation or commitment under
            these terms or the License of Use. The User must use the App responsibly.
        </p>
        <p>
            9.8. We do not guarantee nor do we make any warranties or declarations that use by the User will not
            infringe the rights of third parties. In such a case, the User shall be solely liable.
        </p>
        <h2>10. Exclusion of liability related to digital assets</h2>
        <p>
            10.1. The Owner shall not be liable for any damages that may arise out of or in connection with the use of
            the Wallet. This includes, but is not limited to, direct loss, loss of business or profits, damage caused to
            the User's device and the data contained therein, as well as any other direct or indirect, consequential and
            incidental damages.
        </p>
        <p>
            10.2. The Owner is an independent entity and is in no way a trustee agent whose primary function is the
            provision of digital currencies and their subsequent control in the digital currency market.
        </p>
        <p>
            10.3. The Owner is not liable for any losses or problems that may arise from digital assets transactions
            with third parties or fees charged by the blockchain network on which Kubepay is based.
        </p>
        <p>10.4. The Owner is in no event liable for any digital currency fluctuations that may arise.</p>
        <p>
            10.5. The User assumes that the protocols underlying Kubepay may be subject to sudden changes in the rules
            of operation of the main chain ("hard forks"), and that such hard forks may materially affect the value,
            and/or the operation of the ADA or any other digital currency that the User stores on Kubepay. In this case,
            the User acknowledges and agrees that the Owner may temporarily suspend the App and, at its discretion,
            decide whether to re-configure its systems or cease to support the forked protocol, offering the possibility
            to withdraw funds. For this fact, the Owner disclaims any liability.
        </p>
        <p>
            10.6. The Owner shall not be liable for any malfunction, breakdown, delay or interruption of the Internet
            connection, or if for any other reason the App is unavailable for any period of time. Likewise, it shall not
            be liable for any problems that may arise from the relevant smart contracts, nor for any problems arising
            from the code of the platform on which Kubepay operates.
        </p>
        <p>
            10.7. The Owner is in no way an insured financial institution. As a result, unlike funds held in most banks
            or credit unions, digital currencies made available on the platforms are not insured against loss.
        </p>
        <h2>11. Compensation</h2>
        <p>
            11.1. If any damages, losses or costs (including lawyers' and attorneys' fees) are suffered by the Owner as
            a result of a breach by the User of these Conditions and License of Use or any other that is applicable, it
            would entail the obligation to compensate the Owner.
        </p>
        <p>
            11.2. The same shall apply in the event that, as a result of the User's breaches, claims are made against
            the Owner by third parties, in which case, the User shall compensate the Owner, who will reclaim any
            expenses, costs, damages or losses arising from their actions.
        </p>
        <h2>12. Modifications</h2>
        <p>
            12.1. These present Conditions may be modified and/or updated at any time without prior notice. The
            modifications made shall come into force as soon as they are published on the App, regardless of the means
            and form used to do so.
        </p>
        <p>12.2. The modification shall only affect Users who have accepted them after such modification.</p>
        <h2>13. Duration, termination and exclusion</h2>
        <p>
            13.1. The duration of these Conditions is indefinite and lasts for as long as the User maintains his/her
            account with Kubepay.
        </p>
        <p>
            13.2. At any time, the User may terminate these Conditions by simply canceling his/her account through the
            App's tools designed for this purpose.
        </p>
        <p>
            13.3. For its part, the Owner reserves the right to terminate these Conditions or to block or delete the
            User's account in the event of serious and/or repeated breach of the conditions set forth herein or any
            other applicable conditions.
        </p>
        <h2>14. Data protection</h2>
        <p>
            14.1. The collection and processing of the User's personal data collected by the Owner through Kubepay is
            governed by a Privacy Policy that can be accessed in the Kubepay App, and also beforehand in the app stores.
        </p>
        <p>14.2. At any time the Registered User may delete or reset their Wallet through the "Settings" section.</p>
        <h2>15. Waiver, safeguarding and interpretation</h2>
        <p>15.1. These Conditions constitute the sole agreement between the User and the Owner.</p>
        <p>
            15.2. If the competent Authority declares any provision to be illegal, invalid or unenforceable, it will
            mean that the same shall be interpreted in the manner closest to the original intention of such provision.
            However, such a declaration in respect of any provision or provisions shall not prejudice the validity of
            the remaining provisions.
        </p>
        <p>
            15.3. The failure of the Owner to require strict performance of any terms of these Conditions shall not
            constitute or be construed as a waiver by the Owner to require such performance in the future.
        </p>
        <h2>16. Language </h2>
        <p>
            16.1. The language applicable to these Conditions is Spanish. If an English version is offered, it is merely
            as a courtesy, for the convenience of the User. Therefore, the User expressly accepts that these Conditions
            shall be governed by the Spanish version.
        </p>
        <p>
            16.2. If there is any contradiction between what is indicated in the Spanish version of these Conditions and
            what is indicated in the translation, in any case the Spanish version shall prevail.
        </p>
        <h2>17. Legislation and jurisdiction</h2>
        <p>
            The relations between the Owner and the User shall be governed by Spanish law and, in the event of any
            conflict in the interpretation or fulfillment of these Conditions, they shall submit and be subject to the
            Courts and Tribunals of Barcelona, expressly waiving any other jurisdiction that may correspond to them,
            unless another jurisdiction is established by law.
        </p>
        <h2>18. KubeCoin Staking Program Terms and Conditions</h2>
        <p>
            Welcome to the KubeCoin Staking Program facilitated by the KubePay app. By participating in this program,
            you agree to the following terms and conditions:
        </p>
        <p>Staking Tiers and Lock-in Periods</p>
        <p>
            As a participant in the KubeCoin Staking Program, you can choose from the following tiers, each with
            different Annual Percentage Yields (APY) and lock-in periods:
        </p>
        <p>
            <b> Explorer Tier:</b>
        </p>
        <p> - APY: 3.5.%</p>
        <p> - Lock-in Period: No lock-in period. Assets can be moved freely without restrictions.</p>
        <p>
            <b> Voyager Tier:</b>
        </p>
        <p> - APY: 7.77%</p>
        <p>
            - Lock-in Period: Up to 6 months. During this time, assets staked under this tier cannot be moved or
            withdrawn.
        </p>
        <p>
            <b> Believer Tier:</b>
        </p>
        <p> - APY: 10.5%</p>
        <p>
            {' '}
            - Lock-in Period: Up to 12 months. During this time, assets staked under this tier cannot be moved or
            withdrawn.
        </p>
        <h3>Legal Implications of Asset Lock-in</h3>
        <p>
            By participating in the Voyager and Believer tiers, you acknowledge and agree that your staked assets will
            be locked for the duration specified and cannot be accessed for any transactions outside of the staking
            program. This lock-in is essential to maintain the integrity and stability of the staking rewards
            distribution.
        </p>
        <h3>Staking Limits</h3>
        <p>
            To ensure fair participation and prevent monopolization of the staking process, the following staking limits
            may apply:
        </p>
        <p>
            {' '}
            • No user may stake more than Z% of the total KUBE supply per staking tier. This limit is designed to
            prevent supply concentration and ensure wide participation across the network.
        </p>
        <h3>Rights Reserved</h3>
        <p>
            Kube Ecosystem reserves the right to modify the terms of the staking program, including but not limited to
            APY rates, lock-in periods, and staking limits. Changes will be communicated through official channels and
            will take effect from the date specified in the notification.
        </p>
        <p>
            By agreeing to these terms, you commit to abide by the rules and regulations outlined in the KubeCoin
            Staking Program and understand the restrictions and conditions of your participation.
        </p>
        <h3>Responsibility for Staking</h3>
        <p>
            By participating in the KubeCoin Staking Program, you acknowledge and accept full responsibility for the
            funds you commit to staking. While we have conducted an audit of the smart contract to ensure its
            operational integrity, Kube Ecosystem is not liable for any losses that may occur due to technical failures,
            security breaches, or other unforeseen circumstances. By agreeing to these terms, you understand and agree
            that any funds lost due to such issues are solely your responsibility, and you waive the right to hold Kube
            Ecosystem accountable for any such losses. Your participation is at your own risk, and you acknowledge that
            the staking process involves inherent risks associated with digital assets.
        </p>
    </>
);

export default TermsEN;
