const handleError = (error: any) => {
  const errorDetail = error.response?.data?.detail;
  const errorMessage = error.response?.data?.message;
  const otherError = error.response?.data;

  if (errorDetail) return errorDetail;
  if (errorMessage) return errorMessage;

  if (typeof otherError === 'object') {
    const errorList = Object.values(otherError);
    const firstError = errorList[0] as any;

    if (typeof firstError === 'string') {
      return firstError;
    }

    const errorCleaned = JSON.stringify(firstError).replace(/["\][]/g, '');

    return errorCleaned;
  }

  return error.message;
};

export default handleError;
