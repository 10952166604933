import { matchRoutes, Route, Routes, useLocation } from 'react-router-dom';
import Footer from './components/global/footer/Footer';
import Header from './components/global/header/Header';
import ContactSales from './components/pages/contactSales/ContactSales';
import ContactUs from './components/pages/contactUs/ContactUs';
import Faqs from './components/pages/faqs/Faqs';
import Home from './components/pages/home/Home';
import Congrats from './components/pages/kyc/congrats/Congrats';
import StartVerification from './components/pages/kyc/startVerification/StartVerification';
import DocumentType from './components/pages/kyc/documentType/DocumentType';
import UploadDocument from './components/pages/kyc/uploadDocument/UploadDocument';
import ProofOfAddress from './components/pages/kyc/proofOfAddress/ProofOfAddress';
import FinishVerification from './components/pages/kyc/finishVerification/FinishVerification';
import Pricing from './components/pages/pricing/Pricing';
import Products from './components/pages/products/Products';
import PrivacyPolicy from './components/pages/termsPolicy/PrivacyPolicy';
import TermsOfUse from './components/pages/termsPolicy/TermsOfUse';
import useHelmet from './hooks/useHelmet';
import useScrollToTop from './hooks/useScrollToTop';
import routes, { kycRoutes } from './utils/routes';

const App = () => {
    const { HelmetContainer } = useHelmet();
    const location = useLocation();
    const isKycRoute = matchRoutes(kycRoutes, location);
    useScrollToTop();

    return (
        <>
            <HelmetContainer />
            {!isKycRoute && <Header />}
            <main>
                <Routes>
                    <Route path={routes.home} element={<Home />} />
                    <Route path={routes.products} element={<Products />} />
                    <Route path={routes.pricing} element={<Pricing />} />
                    <Route path={routes.contact} element={<ContactUs />} />
                    <Route path={routes.contactSales} element={<ContactSales />} />
                    <Route path={routes.faqs} element={<Faqs />} />
                    <Route path={routes.terms} element={<TermsOfUse />} />
                    <Route path={routes.privacy} element={<PrivacyPolicy />} />

                    <Route path={routes.kycStartVerification} element={<StartVerification />} />
                    <Route path={routes.kycDocumentType} element={<DocumentType />} />
                    <Route path={routes.kycUploadDocument} element={<UploadDocument />} />
                    <Route path={routes.kycProofOfAddess} element={<ProofOfAddress />} />
                    <Route path={routes.kycFinishVerification} element={<FinishVerification />} />
                    <Route path={routes.kycCongrats} element={<Congrats />} />

                    <Route path="*" element={<Home />} />
                </Routes>
            </main>
            {!isKycRoute && <Footer />}
        </>
    );
};

export default App;
