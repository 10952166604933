import { useTranslation } from 'react-i18next';
import kubeAppImg from '../../../../assets/images/kube-app-screen.png';
import mapBackground from '../../../../assets/images/map-background.png';
import styles from './Design.module.scss';

const Design = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.sectionWrapper}>
            <div className={styles.sectionBackground} />
            <div className={styles.contentWrapper}>
                <div className={styles.textContainer}>
                    <h2>{t('designedToBeSimple')}</h2>
                    <p>{t('straightforward')}</p>
                    <p>{t('designedToBeSimpleParag2')}</p>
                </div>
                <div className={styles.imagesContainer}>
                    <img src={mapBackground} className={styles.mapBackground} alt="" />
                </div>
            </div>
        </section>
    );
};

export default Design;
