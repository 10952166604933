import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Inputs.module.scss';

interface IProps {
  label: string;
  text: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  errorMessage?: string;
}

const TextArea = ({ label, text, value, setValue, errorMessage }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.formLine} ${styles.labelPosition}`}>
      <label htmlFor="message" className={styles.label}>{t(`${label}`)}</label>
      <div className={styles.inputWrapper}>
        <textarea
          name="message"
          placeholder={`${t(`${text}`)}...`}
          maxLength={1000}
          style={{ resize: 'none' }}
          required
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          className={`${styles.input} ${styles.textarea}`}
        />
        <span className={styles.errorMessage}>{t(`${errorMessage}`)}</span>
      </div>
    </div>
  );
};

export default TextArea;
