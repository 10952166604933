import { useTranslation } from 'react-i18next';
import coverImage from '../../../../assets/images/home-cover-image.png';
import googlePlayButton from '../../../../assets/images/google-play-button.png';
import appStoreButton from '../../../../assets/images/app-store-button.png';
import styles from './Cover.module.scss';

const Cover = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.sectionWrapper}>
            <div className={styles.sectionBackground} />
            <div className={styles.contentWrapper}>
                <div className={styles.textContainer}>
                    <h1>{t('coverTitle')}</h1>
                    <p>{t('coverDescription')}</p>
                    <p className={styles.buttonsTitle}>{t('getInOn')}</p>
                    <div className={styles.buttonsContainer}>
                        <a
                            href="https://play.google.com/store/apps/details?id=kubepai.io.kubepay"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className={styles.googlePlayButton} src={googlePlayButton} alt="" />
                        </a>
                        <a
                            href="https://apps.apple.com/es/app/kubepay/id1610520310"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className={styles.appStoreButton} src={appStoreButton} alt="" />
                        </a>
                    </div>
                </div>
                <div className={styles.imageContainer}>
                    <img src={coverImage} className={styles.image} alt="" />
                </div>
            </div>
        </section>
    );
};

export default Cover;
