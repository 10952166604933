import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Inputs.module.scss';
import arrowUp from '../../../assets/images/arrow-up.png';
import arrowDown from '../../../assets/images/arrow-down.png';
import useClickOutside from '../../../hooks/useClickOutside';

interface IProps {
  label: string;
  text: string;
  prefix: string;
  setPrefix: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  errorMessage: string;
}

interface IOptionsProps {
  countryCodes: any;
  onItemClick: any;
}

const PhoneInput = ({ label, text, prefix, setPrefix, phone, setPhone, errorMessage }: IProps) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);
  const selectorRef = useRef<any>(null);
  const countryCodes = ['+34', '+39', '+33', '+351', '+44', '+353', '+49', '+43'];

  const onSelectorClick = () => setIsActive(!isActive);
  const onCountryCodeChange = (countryCode: string) => setPrefix(countryCode);

  const SelectorOptions = ({ countryCodes, onItemClick }: IOptionsProps) => (
    <div className={`${styles.selectorOptionsPhone}`}>
      <ul>
        {countryCodes.map((countryCode: number, index: number) => (
          <li key={countryCode}>
            <button type="button" onClick={() => onItemClick(countryCode)} tabIndex={index} className={styles.codeBtn}>
              {countryCode}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );

  useClickOutside(selectorRef, () => {
    if (isActive) {
      setIsActive(false);
    }
  });


  return (
    <div className={styles.formLine}>
      <label htmlFor={label} className={styles.label}>{t(`${label}`)}</label>
      <div className={styles.inputWrapper}>
        <div className={`${styles.input} ${styles.phoneDiv} `}>
          <button type="button" className={styles.phoneBtn} onClick={onSelectorClick} ref={selectorRef}>
            {prefix}
            <div className={styles.selectorIcon}>
              <img src={isActive ? arrowUp : arrowDown} alt="" />
            </div>
            {isActive && <SelectorOptions countryCodes={countryCodes} onItemClick={onCountryCodeChange} />}
          </button>
          <input
            type="number"
            value={phone}
            aria-label={label}
            onChange={(e: any) => setPhone(e.target.value)}
            placeholder={text}
            required
            className={`${styles.phoneInput} ${isActive && styles.overlay}`}
          />
        </div>
        <span className={styles.errorMessage}>{t(`${errorMessage}`)}</span>
      </div>
    </div>
  );
};

export default PhoneInput;
