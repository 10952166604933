// @ts-nocheck
import LanguageDetector from 'i18next-browser-languagedetector';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import arrowDownIcon from '../../../assets/images/arrow-down.svg';
import useClickOutside from '../../../hooks/useClickOutside';
import i18n from '../../../i18n/config';
import styles from './LanguageSelector.module.scss';

interface IProps {
    from?: string;
}

export const LanguageSelector = ({ from }: IProps) => {
    const { t } = useTranslation();
    const [lang, setLang] = useState(t('english'));
    const [showLangSelector, setShowLangSelector] = useState(false);
    const flagsContainer = useRef<any>(null);
    const isFromTerms = from === 'terms';

    const onClickOutside = () => {
        if (showLangSelector) setShowLangSelector(false);
    };

    useClickOutside(flagsContainer, onClickOutside);

    const onLanguageMenuClick = () => {
        setShowLangSelector(true);
    };

    const onLanguageClick = (newLang: string) => {
        setLanguageText();
        setShowLangSelector(false);
        i18n.changeLanguage(newLang);
    };

    const setLanguageText = useCallback(() => {
        const languageUsed = i18n.use(LanguageDetector).language;
        switch (languageUsed.toUpperCase().split('-')[0]) {
            case 'ES':
                setLang(t('spanish'));
                break;
            case 'EN':
                setLang(t('english'));
                break;
            default:
                setLang(t('english'));
        }
    }, [t]);

    useEffect(() => {
        setLanguageText();
    }, [setLanguageText]);

    return (
        <div
            className={`${styles.languageSelector} ${isFromTerms && styles.languageSelectorTerms}`}
            ref={flagsContainer}
        >
            <button className={styles.flagMenuIcon} onClick={onLanguageMenuClick}>
                {lang}
                <img
                    src={arrowDownIcon}
                    className={`${styles.arrowIcon} ${showLangSelector && styles.flipIcon}`}
                    alt=""
                />
            </button>
            {showLangSelector && (
                <nav className={styles.languageNav}>
                    <ul className={styles.languageList}>
                        <li value="en" onClick={() => onLanguageClick('en')}>
                            {t('english')}
                        </li>
                        <li value="es" onClick={() => onLanguageClick('es')}>
                            {t('spanish')}
                        </li>
                    </ul>
                </nav>
            )}
        </div>
    );
};

export default LanguageSelector;
