import { Dispatch, SetStateAction } from 'react';
import styles from './InputFile.module.scss';
import uploadIcon from '../../../../../assets/images/upload-icon.svg';

interface IProps {
    setValue: any;
    name: string;
    setErrors: Dispatch<SetStateAction<string>>;
    setHasUploaded: Dispatch<SetStateAction<boolean>>;
    setReUpload: Dispatch<SetStateAction<boolean>>;
    children: any;
}

const InputFile = ({ setValue, name, setErrors, setHasUploaded, setReUpload, children }: IProps) => {
    const handleChange = (e: any) => {
        const file = e.target.files[0];
        const maxSize: number = 5242880.0;
        // const maxSize: number = 52.0;
        if (file !== undefined && file.size > maxSize) setErrors('Maximum image size: 5MB');
        else {
            const reader = new FileReader();
            reader.onloadend = () => {
                setValue(file);
            };
            reader.readAsDataURL(file);

            if (!(file.type === 'image/png' || file.type === 'image/jpeg')) setErrors('Please select a valid picture');
            else {
                setErrors('');
                setHasUploaded(true);
                setReUpload(true);
            }
        }
    };

    const handleClick = () => {
        setErrors('');
    };

    return (
        <div className={styles.uploadContainer}>
            <input
                type="file"
                id={name}
                name={name}
                accept="image/png, image/jpeg"
                onChange={handleChange}
                onClick={handleClick}
            />
            <label htmlFor={name}>{children}</label>
            <img src={uploadIcon} alt="Upload icon" />
        </div>
    );
};

export default InputFile;
