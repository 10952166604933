import styles from './StepIndicator.module.scss';
import leftArrowIcon from '../../../../../assets/images/leftArrow-icon.svg';
import rightArrowIcon from '../../../../../assets/images/rightArrow-icon.svg';

interface IProps {
    step: number;
    handleClick: () => void;
}

const StepIndicator = ({ step, handleClick }: IProps) => {
    const btnText = step === 1 ? 'start' : 'back';

    return (
        <div className={styles.indicatorContainer}>
            <div className={styles.iconContainer}>
                <img src={step === 1 ? rightArrowIcon : leftArrowIcon} alt="Arrow icon" />
                {/* <img src={step === 1 ? rightArrowIcon : leftArrowIcon} alt="Arrow icon" className={`${step > 1 && styles.flipImg}`} /> */}
                {/* <img src={rightArrowIcon} alt="Arrow icon"  /> */}
            </div>
            <button className={`${styles.btn} ${step > 1 && styles.btnBack}`} onClick={handleClick}>
                {btnText}
            </button>
            <div className={styles.stepContainer}>
                <div className={`${styles.dot} ${styles.filledDot}`}> </div>
                <div className={`${styles.line} ${step === 1 ? styles.end : styles.solid}`}> </div>
                <div className={`${styles.dot} ${step > 1 && styles.filledDot}`}> </div>
                <div
                    className={`${styles.line} ${step < 2 ? styles.dashed : styles.solid} ${step === 2 && styles.end}`}
                >
                    {' '}
                </div>
                <div className={`${styles.dot} ${step > 2 && styles.filledDot}`}> </div>
                <div
                    className={`${styles.line} ${step < 3 ? styles.dashed : styles.solid} ${step === 3 && styles.end}`}
                >
                    {' '}
                </div>
                <div className={`${styles.dot} ${step > 3 && styles.filledDot}`}> </div>
                <div className={`${styles.line} ${step < 4 ? styles.dashed : styles.end}`}> </div>
                <div className={`${styles.dot}`}> </div>
            </div>
        </div>
    );
};

export default StepIndicator;
