import { useTranslation } from 'react-i18next';
import PrivacyEN from './languages/PrivacyEN';
import PrivacyES from './languages/PrivacyES';
import styles from './common.module.scss';
import LanguageSelector from '../../global/languageSelector/LanguageSelector';

const PrivacyPolicy = () => {
    const { i18n } = useTranslation();
    const currentLang = i18n.language;

    const getContainer = () => {
        if (currentLang === 'en') return <PrivacyEN />;
        if (currentLang === 'es') return <PrivacyES />;
        return <PrivacyEN />;
    };

    return (
        <div className={styles.pageContainer}>
            <div className={styles.pageWrapper}>
                <div className={styles.languageSelectorContainer}>
                    <LanguageSelector from="terms" />
                </div>
                {getContainer()}
            </div>
        </div>
    );
};

export default PrivacyPolicy;
