import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { Document, setCountryId, setDocType, setSelectedCountry } from '../../../../redux/kycSlice';
import styles from './DocumentType.module.scss';
import Button, { DocTypeButton } from '../commons/buttons/Button';
import { routes } from '../../../../utils/routes';
import SelectCountry from '../commons/selectCountry/SelectCountry';
import StepIndicator from '../commons/stepIndicator/StepIndicator';

const DocumentType = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [country, setCountry] = useState<string>('');
    const [countrySelected, setCountrySelected] = useState<string>('Select a country');
    const [idCountry, setIdCountry] = useState<string>('');
    const [document, setDocument] = useState<Document>('');
    const { operationUUID, verificationSecret } = useSelector((state: RootState) => state.kyc);
    const countryId = useSelector((state: RootState) => state.kyc.countryId);
    const docType = useSelector((state: RootState) => state.kyc.docType);
    const selectedCountry = useSelector((state: RootState) => state.kyc.selectedCountry);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (country !== '' && document !== '') {
            dispatch(setCountryId(idCountry));
            dispatch(setDocType(document));
            dispatch(setSelectedCountry(countrySelected));
            navigate(routes.kycUploadDocument);
        }
    };

    const handleBack = () => {
        const replaceOP = routes.kycStartVerification.replace(':operationUUID', operationUUID);
        const replaceSecret = replaceOP.replace(':verificationSecret', verificationSecret);
        const firstStepRoute = replaceSecret;
        navigate(firstStepRoute);
    };

    const handleDocTypeClick = (e: any, type: Document) => {
        e.preventDefault();
        setDocument(type);
    };

    useEffect(() => {
        if (countryId !== '') setIdCountry(countryId);
        if (docType !== '') setDocument(docType);
        if (selectedCountry !== '') {
            setCountrySelected(selectedCountry);
            setCountry(selectedCountry);
        }
    }, []);

    return (
        <section className={styles.stepContainer}>
            <div className={styles.innerStepContainer}>
                <h3>Select the type of document</h3>
                <StepIndicator step={2} handleClick={handleBack} />
                <form noValidate autoComplete="nope">
                    <div>
                        <h4>Country</h4>
                        <p>Select the country that issued your document.</p>
                        <SelectCountry
                            setValue={setCountry}
                            setId={setIdCountry}
                            countrySelected={countrySelected}
                            setCountrySelected={setCountrySelected}
                        />
                        <h4>Document</h4>
                        <p>Choose your document type.</p>
                        <div className={styles.docTypeContainer}>
                            <DocTypeButton
                                onClick={(e: any) => handleDocTypeClick(e, 'passport')}
                                className={`${styles.docTypeBtn} ${
                                    document === 'passport' ? styles.activeColour : styles.inactiveColour
                                }`}
                            >
                                Passport
                            </DocTypeButton>
                            <DocTypeButton
                                onClick={(e: any) => handleDocTypeClick(e, 'dni')}
                                className={`${styles.docTypeBtn} ${
                                    document === 'dni' ? styles.activeColour : styles.inactiveColour
                                }`}
                            >
                                ID card
                            </DocTypeButton>
                            <DocTypeButton
                                onClick={(e: any) => handleDocTypeClick(e, 'residence')}
                                className={`${styles.docTypeBtn} ${
                                    document === 'residence' ? styles.activeColour : styles.inactiveColour
                                }`}
                            >
                                Residence permit
                            </DocTypeButton>
                            <DocTypeButton
                                onClick={(e: any) => handleDocTypeClick(e, 'driver')}
                                className={`${styles.docTypeBtn} ${
                                    document === 'driver' ? styles.activeColour : styles.inactiveColour
                                }`}
                            >
                                Driver&apos;s license
                            </DocTypeButton>
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button dark={false} onClick={handleSubmit} isDisabled={country === '' || document === ''}>
                            Next
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default DocumentType;
