/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './StartVerification.module.scss';
import Button from '../commons/buttons/Button';
import InputCheckboxAndLabel from '../commons/inputs/inputCheckboxAndLabel/InputCheckboxAndLabel';
import { routes } from '../../../../utils/routes';
import StepIndicator from '../commons/stepIndicator/StepIndicator';
import {
    Customer,
    setCustomerData,
    setOperationData,
    setOperationUUID,
    setVerificationSecret,
} from '../../../../redux/kycSlice';
import APIKit from '../../../../utils/APIKit';
import handleError from '../../../../utils/handleError';
import { RootState } from '../../../../redux/store';

interface IResponse {
    created_at: string;
    uuid: string;
    user_wallet: string;
    swap_to: string;
    final_price: string;
    final_price_currency: string;
    customer: Customer;
}

const StartVerification = () => {
    const { operationUUID, verificationSecret } = useParams();
    const navigate = useNavigate();
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
    const [agreedDataProcessing, setAgreedDataProcessing] = useState<boolean>(false);
    const customerData = useSelector((state: RootState) => state.kyc.customer);
    const {
        final_price: price,
        user_wallet: walletAddress,
        created_at,
        final_price_currency: priceCurrency,
    } = useSelector((state: RootState) => state.kyc);
    const createdAt = new Date(created_at);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setOperationUUID(operationUUID));
        dispatch(setVerificationSecret(verificationSecret));
    }, [operationUUID, verificationSecret]);

    useEffect(() => {
        if (operationUUID === '' || verificationSecret === '') return;
        getCustomerData();
    }, [operationUUID, verificationSecret]);

    const getCustomerData = () => {
        APIKit.get(`/api/operations/buy-operation/${operationUUID}/complete/${verificationSecret}/`)
            .then((res) => {
                const data = res.data as IResponse;
                const customerData = data.customer;
                dispatch(setCustomerData(customerData));
                dispatch(
                    setOperationData({
                        createdAt: data.created_at,
                        userWallet: data.user_wallet,
                        swapTo: data.swap_to,
                        finalPrice: data.final_price,
                        finalPriceCurrency: data.final_price_currency,
                    })
                );
            })
            .catch((error) => {
                const errorMessage = handleError(error);
                console.log({ errorMessage });
            });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        navigate(routes.kycDocumentType);
    };

    return (
        <section className={styles.stepContainer}>
            <div className={styles.innerStepContainer}>
                <div className={styles.upperWrapper}>
                    <h3>Let&apos;s get you verified</h3>
                    <StepIndicator step={1} handleClick={() => {}} />
                    <p className={styles.text}>You are about to verify the following transaction:</p>
                    <div className={styles.transactionDetails}>
                        <p className={styles.detail}>
                            <span className={styles.detailName}>Customer:</span> {customerData.first_name}{' '}
                            {customerData.last_name}
                        </p>
                        <p className={styles.detail}>
                            <span className={styles.detailName}>Transaction price:</span> {price} {priceCurrency}
                        </p>
                        <p className={styles.detail}>
                            <span className={styles.detailName}>Transaction date:</span>{' '}
                            {createdAt.toLocaleString('fr-FR')}
                        </p>
                        <p className={styles.detail}>
                            <span className={styles.detailName}>Wallet address:</span>
                            <span className={styles.address}>{walletAddress}</span>
                        </p>
                    </div>
                    <p className={styles.text}>
                        Before you start, please prepare your <span>identity document</span> and make sure it is{' '}
                        <span>valid</span>.
                    </p>
                    <p className={styles.text}>
                        We also require you to accept our T&apos;s &amp; C&apos;s, and to agree our processing of your
                        personal data:
                    </p>
                </div>
                <form noValidate>
                    <div className={styles.inputWrapper}>
                        <InputCheckboxAndLabel
                            value={acceptedTerms}
                            setValue={setAcceptedTerms}
                            name="terms"
                            labelText="By clicking next, I accept the Terms and Conditions"
                        />
                        <p className={styles.text}>
                            By clicking next, I accept the
                            <a href={routes.terms} target="_blank" rel="noreferrer">
                                Terms and Conditions
                            </a>
                        </p>
                    </div>
                    <div className={styles.inputWrapper}>
                        <InputCheckboxAndLabel
                            value={agreedDataProcessing}
                            setValue={setAgreedDataProcessing}
                            name="process"
                            labelText="I agree to the processing of my personal data, as described in the Consent to Personal Data Processing"
                        />
                        <p className={styles.text}>
                            I agree to the processing of my personal data, as described in the
                            <a href={routes.privacy} target="_blank" rel="noreferrer">
                                Consent to Personal Data Processing
                            </a>
                        </p>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            dark={false}
                            onClick={handleSubmit}
                            isDisabled={!(acceptedTerms && agreedDataProcessing)}
                        >
                            Next
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default StartVerification;
