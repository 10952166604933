import Faq from 'react-faq-component';
import { useTranslation } from 'react-i18next';
import styles from './Faqs.module.scss';

const faqComponentStyles = {
    rowTitleColor: '#595959',
    arrowColor: '#436CFF',
    rowContentColor: '#5A5A5A',
    rowContentPaddingTop: '35px',
    rowContentPaddingBottom: '30px',
    rowContentPaddingLeft: '20px',
    rowContentPaddingRight: '15px',
};

const Faqs = () => {
    const { t } = useTranslation('faq');
    const translatedText = (text: string) => t(`${text}`);

    const ContactTeam = () => (
        <span>
            {t('howContactTeamAnswer')}{' '}
            <span className={styles.underline}>info@kubepay.io</span>
            .
        </span>
    );

    const faqQuestions = {
        rows: [
            { title: translatedText('whatIsKubePay'), content: translatedText('whatIsKubePayAnswer'), },
            { title: translatedText('howIntegrateAPI'), content: translatedText('howIntegrateAPIAnswer'), },
            { title: translatedText('howToStart'), content: translatedText('howToStartAnswer'), },
            { title: translatedText('whatFees'), content: translatedText('whatFeesAnswer'), },
            { title: translatedText('whatPaymentMethods'), content: translatedText('whatPaymentMethodsAnswer'), },
            { title: translatedText('minMaxCharge'), content: translatedText('minMaxChargeAnswer'), },
            { title: translatedText('howTransferMoney'), content: translatedText('howTransferMoneyAnswer'), },
            { title: translatedText('howPaymentsProcessed'), content: translatedText('howPaymentsProcessedAnswer'), },
            { title: translatedText('whatTransactionInformation'), content: translatedText('whatTransactionInformationAnswer'), },
            { title: translatedText('rejectFraudulance'), content: translatedText('rejectFraudulanceAnswer'), },
            { title: translatedText('howContactTeam'), content: <ContactTeam /> },
        ],
    };

    return (
        <section className={styles.pageContainer}>
            <h1 className={styles.pageTitle}>{t('faqs')}</h1>
            <p className={styles.pageSubtitle}>{t('helpGetStarted')}</p>
            <section className={styles.sectionContainer}>
                <div className={styles.sectionWrapper}>
                    <Faq data={faqQuestions} styles={faqComponentStyles} />
                </div>
            </section>
        </section>
    );
};

export default Faqs;
