import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import websScreens from '../../../../assets/images/webs-screens.png';
import routes from '../../../../utils/routes';
import styles from './Products.module.scss';

const Products = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onButtonClick = () => {
        navigate(routes.products);
    };

    return (
        <section className={styles.sectionWrapper}>
            <div className={styles.sectionBackground} />
            <div className={styles.contentWrapper}>
                <div className={styles.textContainer}>
                    <h2>{t('products')}</h2>
                    <p>{t('straightforward')}</p>
                    <p>{t('productsParag2')}</p>
                    <button className={styles.button} onClick={onButtonClick}>
                        <span>{t('seeMore')}</span>
                    </button>
                </div>
                <div className={styles.webScreensWrapper}>
                    <img src={websScreens} className={styles.websScreens} alt="" />
                </div>
            </div>
        </section>
    );
};

export default Products;
