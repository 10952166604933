import { v4 as uuidv4 } from 'uuid';

type countryObj = {
    id: string;
    shortCode: string;
    country: string;
};

const CountryList: countryObj[] = [
    { id: uuidv4(), shortCode: 'AX', country: 'Åland Islands' },
    { id: uuidv4(), shortCode: 'DZ', country: 'Algeria' },
    { id: uuidv4(), shortCode: 'AS', country: 'American Samoa' },
    { id: uuidv4(), shortCode: 'AD', country: 'Andorra' },
    { id: uuidv4(), shortCode: 'AO', country: 'Angola' },
    { id: uuidv4(), shortCode: 'AI', country: 'Anguilla' },
    { id: uuidv4(), shortCode: 'AQ', country: 'Antarctica' },
    { id: uuidv4(), shortCode: 'AG', country: 'Antigua and Barbuda' },
    { id: uuidv4(), shortCode: 'AR', country: 'Argentina' },
    { id: uuidv4(), shortCode: 'AM', country: 'Armenia' },
    { id: uuidv4(), shortCode: 'AW', country: 'Aruba' },
    { id: uuidv4(), shortCode: 'AU', country: 'Australia' },
    { id: uuidv4(), shortCode: 'AT', country: 'Austria' },
    { id: uuidv4(), shortCode: 'AZ', country: 'Azerbaijan' },
    { id: uuidv4(), shortCode: 'BS', country: 'Bahamas' },
    { id: uuidv4(), shortCode: 'BH', country: 'Bahrain' },
    { id: uuidv4(), shortCode: 'BD', country: 'Bangladesh' },
    { id: uuidv4(), shortCode: 'BY', country: 'Belarus' },
    { id: uuidv4(), shortCode: 'BE', country: 'Belgium' },
    { id: uuidv4(), shortCode: 'BZ', country: 'Belize' },
    { id: uuidv4(), shortCode: 'BJ', country: 'Benin' },
    { id: uuidv4(), shortCode: 'BM', country: 'Bermuda' },
    { id: uuidv4(), shortCode: 'BT', country: 'Bhutan' },
    { id: uuidv4(), shortCode: 'BO', country: 'Bolivia' },
    { id: uuidv4(), shortCode: 'BQ', country: 'Bonaire, Sint Eustatius and Saba' },
    { id: uuidv4(), shortCode: 'BA', country: 'Bosnia and Herzegovina' },
    { id: uuidv4(), shortCode: 'BV', country: 'Bouvet Island' },
    { id: uuidv4(), shortCode: 'BR', country: 'Brazil' },
    { id: uuidv4(), shortCode: 'IO', country: 'British Indian Ocean Territory' },
    { id: uuidv4(), shortCode: 'BN', country: 'Brunei Darussalam' },
    { id: uuidv4(), shortCode: 'BG', country: 'Bulgaria' },
    { id: uuidv4(), shortCode: 'BI', country: 'Burundi' },
    { id: uuidv4(), shortCode: 'CM', country: 'Cameroon' },
    { id: uuidv4(), shortCode: 'CA', country: 'Canada' },
    { id: uuidv4(), shortCode: 'CV', country: 'Cape Verde' },
    { id: uuidv4(), shortCode: 'CF', country: 'Central African Republic' },
    { id: uuidv4(), shortCode: 'TD', country: 'Chad' },
    { id: uuidv4(), shortCode: 'CL', country: 'Chile' },
    { id: uuidv4(), shortCode: 'CN', country: 'China' },
    { id: uuidv4(), shortCode: 'CX', country: 'Christmas Island' },
    { id: uuidv4(), shortCode: 'CC', country: 'Islands Cocos (Keeling)' },
    { id: uuidv4(), shortCode: 'CO', country: 'Colombia' },
    { id: uuidv4(), shortCode: 'KM', country: 'Comoros' },
    { id: uuidv4(), shortCode: 'CG', country: 'Republic of the Brazzaville (Congo)' },
    { id: uuidv4(), shortCode: 'CD', country: 'The Democratic Republic of the Kinshasa (Congo)' },
    { id: uuidv4(), shortCode: 'CK', country: 'Cook Islands' },
    { id: uuidv4(), shortCode: 'CR', country: 'Costa Rica' },
    { id: uuidv4(), shortCode: 'CI', country: "Republic of Côte d'Ivoire" },
    { id: uuidv4(), shortCode: 'HR', country: 'Croatia' },
    { id: uuidv4(), shortCode: 'CU', country: 'Cuba' },
    { id: uuidv4(), shortCode: 'CW', country: 'Curaçao' },
    { id: uuidv4(), shortCode: 'CY', country: 'Cyprus' },
    { id: uuidv4(), shortCode: 'CZ', country: 'Czech Republic' },
    { id: uuidv4(), shortCode: 'DK', country: 'Denmark' },
    { id: uuidv4(), shortCode: 'DJ', country: 'Djibouti' },
    { id: uuidv4(), shortCode: 'DM', country: 'Dominica' },
    { id: uuidv4(), shortCode: 'DO', country: 'Dominican Republic' },
    { id: uuidv4(), shortCode: 'EC', country: 'Ecuador' },
    { id: uuidv4(), shortCode: 'EG', country: 'Egypt' },
    { id: uuidv4(), shortCode: 'SV', country: 'El Salvador' },
    { id: uuidv4(), shortCode: 'GQ', country: 'Equatorial Guinea' },
    { id: uuidv4(), shortCode: 'ER', country: 'Eritrea' },
    { id: uuidv4(), shortCode: 'EE', country: 'Estonia' },
    { id: uuidv4(), shortCode: 'FK', country: 'Falkland Islands (Islas Malvinas)' },
    { id: uuidv4(), shortCode: 'FO', country: 'Faroe Islands' },
    { id: uuidv4(), shortCode: 'FJ', country: 'Fiji' },
    { id: uuidv4(), shortCode: 'FI', country: 'Finland' },
    { id: uuidv4(), shortCode: 'FR', country: 'France' },
    { id: uuidv4(), shortCode: 'GF', country: 'French Guiana' },
    { id: uuidv4(), shortCode: 'PF', country: 'French Polynesia' },
    { id: uuidv4(), shortCode: 'TF', country: 'French Southern and Antarctic Lands' },
    { id: uuidv4(), shortCode: 'GA', country: 'Gabon' },
    { id: uuidv4(), shortCode: 'GM', country: 'Gambia, The' },
    { id: uuidv4(), shortCode: 'GE', country: 'Georgia' },
    { id: uuidv4(), shortCode: 'DE', country: 'Germany' },
    { id: uuidv4(), shortCode: 'GH', country: 'Ghana' },
    { id: uuidv4(), shortCode: 'GI', country: 'Gibraltar' },
    { id: uuidv4(), shortCode: 'GR', country: 'Greece' },
    { id: uuidv4(), shortCode: 'GL', country: 'Greenland' },
    { id: uuidv4(), shortCode: 'GD', country: 'Grenada' },
    { id: uuidv4(), shortCode: 'GP', country: 'Guadeloupe' },
    { id: uuidv4(), shortCode: 'GU', country: 'Guam' },
    { id: uuidv4(), shortCode: 'GT', country: 'Guatemala' },
    { id: uuidv4(), shortCode: 'GG', country: 'Guernsey' },
    { id: uuidv4(), shortCode: 'GN', country: 'Guinea' },
    { id: uuidv4(), shortCode: 'GW', country: 'Guinea-Bissau' },
    { id: uuidv4(), shortCode: 'GY', country: 'Guyana' },
    { id: uuidv4(), shortCode: 'HM', country: 'Heard Island and McDonald Islands' },
    { id: uuidv4(), shortCode: 'VA', country: 'Holy See (Vatican City)' },
    { id: uuidv4(), shortCode: 'HN', country: 'Honduras' },
    { id: uuidv4(), shortCode: 'HK', country: 'Hong Kong' },
    { id: uuidv4(), shortCode: 'HU', country: 'Hungary' },
    { id: uuidv4(), shortCode: 'IS', country: 'Iceland' },
    { id: uuidv4(), shortCode: 'IN', country: 'India' },
    { id: uuidv4(), shortCode: 'ID', country: 'Indonesia' },
    { id: uuidv4(), shortCode: 'IE', country: 'Ireland' },
    { id: uuidv4(), shortCode: 'IM', country: 'Isle of Man' },
    { id: uuidv4(), shortCode: 'IL', country: 'Israel' },
    { id: uuidv4(), shortCode: 'IT', country: 'Italy' },
    { id: uuidv4(), shortCode: 'JP', country: 'Japan' },
    { id: uuidv4(), shortCode: 'JE', country: 'Jersey' },
    { id: uuidv4(), shortCode: 'KZ', country: 'Kazakhstan' },
    { id: uuidv4(), shortCode: 'KE', country: 'Kenya' },
    { id: uuidv4(), shortCode: 'KI', country: 'Kiribati' },
    { id: uuidv4(), shortCode: 'KR', country: 'Republic of Korea' },
    { id: uuidv4(), shortCode: 'XK', country: 'Kosovo' },
    { id: uuidv4(), shortCode: 'KW', country: 'Kuwait' },
    { id: uuidv4(), shortCode: 'KG', country: 'Kyrgyzstan' },
    { id: uuidv4(), shortCode: 'LA', country: 'Laos' },
    { id: uuidv4(), shortCode: 'LV', country: 'Latvia' },
    { id: uuidv4(), shortCode: 'LB', country: 'Lebanon' },
    { id: uuidv4(), shortCode: 'LS', country: 'Lesotho' },
    { id: uuidv4(), shortCode: 'LR', country: 'Liberia' },
    { id: uuidv4(), shortCode: 'LY', country: 'Libya' },
    { id: uuidv4(), shortCode: 'LI', country: 'Liechtenstein' },
    { id: uuidv4(), shortCode: 'LT', country: 'Lithuania' },
    { id: uuidv4(), shortCode: 'LU', country: 'Luxembourg' },
    { id: uuidv4(), shortCode: 'MO', country: 'Macao' },
    { id: uuidv4(), shortCode: 'MK', country: 'Republic of Macedonia' },
    { id: uuidv4(), shortCode: 'MG', country: 'Madagascar' },
    { id: uuidv4(), shortCode: 'MW', country: 'Malawi' },
    { id: uuidv4(), shortCode: 'MY', country: 'Malaysia' },
    { id: uuidv4(), shortCode: 'MV', country: 'Maldives' },
    { id: uuidv4(), shortCode: 'MH', country: 'Marshall Islands' },
    { id: uuidv4(), shortCode: 'MQ', country: 'Martinique' },
    { id: uuidv4(), shortCode: 'MR', country: 'Mauritania' },
    { id: uuidv4(), shortCode: 'YT', country: 'Mayotte' },
    { id: uuidv4(), shortCode: 'MX', country: 'Mexico' },
    { id: uuidv4(), shortCode: 'FM', country: 'Federated States of Micronesia' },
    { id: uuidv4(), shortCode: 'MD', country: 'Moldova' },
    { id: uuidv4(), shortCode: 'MC', country: 'Monaco' },
    { id: uuidv4(), shortCode: 'MN', country: 'Mongolia' },
    { id: uuidv4(), shortCode: 'ME', country: 'Montenegro' },
    { id: uuidv4(), shortCode: 'MS', country: 'Montserrat' },
    { id: uuidv4(), shortCode: 'MZ', country: 'Mozambique' },
    { id: uuidv4(), shortCode: 'NA', country: 'Namibia' },
    { id: uuidv4(), shortCode: 'NR', country: 'Nauru' },
    { id: uuidv4(), shortCode: 'NP', country: 'Nepal' },
    { id: uuidv4(), shortCode: 'NL', country: 'Netherlands' },
    { id: uuidv4(), shortCode: 'NC', country: 'New Caledonia' },
    { id: uuidv4(), shortCode: 'NZ', country: 'New Zealand' },
    { id: uuidv4(), shortCode: 'NE', country: 'Niger' },
    { id: uuidv4(), shortCode: 'NG', country: 'Nigeria' },
    { id: uuidv4(), shortCode: 'NU', country: 'Niue' },
    { id: uuidv4(), shortCode: 'NF', country: 'Norfolk Island' },
    { id: uuidv4(), shortCode: 'MP', country: 'Northern Mariana Islands' },
    { id: uuidv4(), shortCode: 'NO', country: 'Norway' },
    { id: uuidv4(), shortCode: 'OM', country: 'Oman' },
    { id: uuidv4(), shortCode: 'PW', country: 'Palau' },
    { id: uuidv4(), shortCode: 'PS', country: 'State of Palestine' },
    { id: uuidv4(), shortCode: 'PG', country: 'Papua New Guinea' },
    { id: uuidv4(), shortCode: 'PY', country: 'Paraguay' },
    { id: uuidv4(), shortCode: 'PE', country: 'Peru' },
    { id: uuidv4(), shortCode: 'PN', country: 'Pitcairn' },
    { id: uuidv4(), shortCode: 'PL', country: 'Poland' },
    { id: uuidv4(), shortCode: 'PT', country: 'Portugal' },
    { id: uuidv4(), shortCode: 'PR', country: 'Puerto Rico' },
    { id: uuidv4(), shortCode: 'QA', country: 'Qatar' },
    { id: uuidv4(), shortCode: 'RE', country: 'Réunion' },
    { id: uuidv4(), shortCode: 'RO', country: 'Romania' },
    { id: uuidv4(), shortCode: 'RU', country: 'Russian Federation' },
    { id: uuidv4(), shortCode: 'RW', country: 'Rwanda' },
    { id: uuidv4(), shortCode: 'BL', country: 'Saint Barthélemy' },
    { id: uuidv4(), shortCode: 'SH', country: 'Ascension and Tristan da Cunha, Saint Helena' },
    { id: uuidv4(), shortCode: 'KN', country: 'Saint Kitts and Nevis' },
    { id: uuidv4(), shortCode: 'LC', country: 'Saint Lucia' },
    { id: uuidv4(), shortCode: 'MF', country: 'Saint Martin' },
    { id: uuidv4(), shortCode: 'PM', country: 'Saint Pierre and Miquelon' },
    { id: uuidv4(), shortCode: 'VC', country: 'Saint Vincent and the Grenadines' },
    { id: uuidv4(), shortCode: 'WS', country: 'Samoa' },
    { id: uuidv4(), shortCode: 'SM', country: 'San Marino' },
    { id: uuidv4(), shortCode: 'ST', country: 'Sao Tome and Principe' },
    { id: uuidv4(), shortCode: 'SA', country: 'Saudi Arabia' },
    { id: uuidv4(), shortCode: 'RS', country: 'Serbia' },
    { id: uuidv4(), shortCode: 'SC', country: 'Seychelles' },
    { id: uuidv4(), shortCode: 'SL', country: 'Sierra Leone' },
    { id: uuidv4(), shortCode: 'SG', country: 'Singapore' },
    { id: uuidv4(), shortCode: 'SX', country: 'Sint Maarten (Dutch part)' },
    { id: uuidv4(), shortCode: 'SK', country: 'Slovakia' },
    { id: uuidv4(), shortCode: 'SI', country: 'Slovenia' },
    { id: uuidv4(), shortCode: 'SB', country: 'Solomon Islands' },
    { id: uuidv4(), shortCode: 'SO', country: 'Somalia' },
    { id: uuidv4(), shortCode: 'ZA', country: 'South Africa' },
    { id: uuidv4(), shortCode: 'GS', country: 'South Georgia and South Sandwich Islands' },
    { id: uuidv4(), shortCode: 'ES', country: 'Spain' },
    { id: uuidv4(), shortCode: 'SD', country: 'Sudan' },
    { id: uuidv4(), shortCode: 'SR', country: 'Suriname' },
    { id: uuidv4(), shortCode: 'SZ', country: 'Swaziland' },
    { id: uuidv4(), shortCode: 'SE', country: 'Sweden' },
    { id: uuidv4(), shortCode: 'CH', country: 'Switzerland' },
    { id: uuidv4(), shortCode: 'TW', country: 'Taiwan' },
    { id: uuidv4(), shortCode: 'TJ', country: 'Tajikistan' },
    { id: uuidv4(), shortCode: 'TZ', country: 'United Republic of Tanzania' },
    { id: uuidv4(), shortCode: 'TH', country: 'Thailand' },
    { id: uuidv4(), shortCode: 'TL', country: 'Timor-Leste' },
    { id: uuidv4(), shortCode: 'TG', country: 'Togo' },
    { id: uuidv4(), shortCode: 'TK', country: 'Tokelau' },
    { id: uuidv4(), shortCode: 'TO', country: 'Tonga' },
    { id: uuidv4(), shortCode: 'TR', country: 'Turkey' },
    { id: uuidv4(), shortCode: 'TM', country: 'Turkmenistan' },
    { id: uuidv4(), shortCode: 'TC', country: 'Turks and Caicos Islands' },
    { id: uuidv4(), shortCode: 'TV', country: 'Tuvalu' },
    { id: uuidv4(), shortCode: 'UA', country: 'Ukraine' },
    { id: uuidv4(), shortCode: 'AE', country: 'United Arab Emirates' },
    { id: uuidv4(), shortCode: 'GB', country: 'United Kingdom' },
    { id: uuidv4(), shortCode: 'US', country: 'United States' },
    { id: uuidv4(), shortCode: 'UM', country: 'United States Minor Outlying Islands' },
    { id: uuidv4(), shortCode: 'UY', country: 'Uruguay' },
    { id: uuidv4(), shortCode: 'UZ', country: 'Uzbekistan' },
    { id: uuidv4(), shortCode: 'VN', country: 'Vietnam' },
    { id: uuidv4(), shortCode: 'VG', country: 'British Virgin Islands' },
    { id: uuidv4(), shortCode: 'VI', country: 'U.S. Virgin Islands' },
    { id: uuidv4(), shortCode: 'WF', country: 'Wallis and Futuna' },
    { id: uuidv4(), shortCode: 'EH', country: 'Western Sahara' },
    { id: uuidv4(), shortCode: 'ZM', country: 'Zambia' },
];

export default CountryList;
