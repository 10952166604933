export const routes = {
    home: '/',
    products: '/products',
    pricing: '/pricing',
    contact: '/contact',
    contactSales: '/contact-sales',
    faqs: '/faqs',
    terms: '/wallet/terms',
    privacy: '/wallet/privacy',
    kycStartVerification: '/kyc/verification/:operationUUID/:verificationSecret',
    kycDocumentType: '/kyc/document-type',
    kycUploadDocument: '/kyc/upload-document',
    kycProofOfAddess: '/kyc/proof-of-address',
    kycFinishVerification: '/kyc/finish-verification',
    kycCongrats: '/kyc/congrats',
};

export const kycRoutes = [
    { path: routes.kycStartVerification },
    { path: routes.kycDocumentType },
    { path: routes.kycUploadDocument },
    { path: routes.kycProofOfAddess },
    { path: routes.kycFinishVerification },
    { path: routes.kycCongrats },
];

export default routes;
