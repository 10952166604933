import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetKycStates } from '../redux/kycSlice';
import { RootState } from '../redux/store';
import APIKit from '../utils/APIKit';
import handleError from '../utils/handleError';
import routes from '../utils/routes';

const useKyc = () => {
    const frontPicture = useSelector((state: RootState) => state.kyc.documentFront);
    const backPicture = useSelector((state: RootState) => state.kyc.documentBack);
    const proofOfAddress = useSelector((state: RootState) => state.kyc.proofOfAddress);
    const selfiePic = useSelector((state: RootState) => state.kyc.selfiePic);
    const customerData = useSelector((state: RootState) => state.kyc.customer);
    const countryId = useSelector((state: RootState) => state.kyc.countryId);
    const docType = useSelector((state: RootState) => state.kyc.docType);
    const operationUUID = useSelector((state: RootState) => state.kyc.operationUUID);
    const verificationSecret = useSelector((state: RootState) => state.kyc.verificationSecret);
    const [uploadPercentage, setUploadPercentage] = useState<number>(0);
    const [uploadError, setUploadError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitKyc = () => {
        const data = new FormData();
        data.append('customer.selfie', selfiePic);
        data.append('customer.doc_front_image', frontPicture);

        data.append('customer.doc_type', docType);
        data.append('customer.country', countryId);
        if (docType !== 'passport') {
            data.append('customer.doc_back_image', backPicture);
        }
        data.append('customer.doc_bill_proof', proofOfAddress);
        data.append('customer.first_name', customerData.first_name);
        data.append('customer.last_name', customerData.last_name);
        data.append('customer.postal_code', customerData.postal_code);

        const options = {
            onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                const percent = Math.floor((loaded * 100) / total);

                if (percent <= 100) {
                    setUploadPercentage(percent);
                }
            },
        };

        APIKit.put(`/api/operations/buy-operation/${operationUUID}/complete/${verificationSecret}/`, data, options)
            .then((res) => {
                dispatch(resetKycStates());
                navigate(routes.kycCongrats);
            })
            .catch((error) => {
                console.log({ error });

                const errorMessage = handleError(error);

                setUploadError(errorMessage);
                setUploadPercentage(0);
            });
    };

    return { submitKyc, uploadPercentage, setUploadPercentage, uploadError, setUploadError };
};

export default useKyc;
