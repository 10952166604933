import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelfiePic } from '../../../../redux/kycSlice';
import { RootState } from '../../../../redux/store';
import styles from './FinishVerification.module.scss';
import Button from '../commons/buttons/Button';
import { routes } from '../../../../utils/routes';
import UploadDocument from '../commons/inputFile/InputFile';
import MessageBox from '../commons/messageBox/MessageBox';
import UploadSucceed from '../commons/uploadSucceed/UploadSucceed';
import redoActionIcon from '../../../../assets/images/redoAction-icon.svg';
import StepIndicator from '../commons/stepIndicator/StepIndicator';
import useKyc from '../../../../hooks/useKyc';

const FinishVerification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selfiePicture, setSelfiePicture] = useState<any>(new File([], ''));
    const [pictureError, setPictureError] = useState<string>('');
    const [hasPictureUploaded, setHasPictureUploaded] = useState<boolean>(false);
    const [reUpload, setReUpload] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const documentSelfie = useSelector((state: RootState) => state.kyc.selfiePic);
    const { submitKyc, uploadPercentage, setUploadPercentage, uploadError, setUploadError } = useKyc();
    const showPercentageBar = uploadPercentage > 0;

    useEffect(() => {
        if (uploadPercentage === 0 && hasPictureUploaded) setIsDisabled(false);
    }, [uploadPercentage, hasPictureUploaded]);

    useEffect(() => {
        if ('name' in documentSelfie && documentSelfie.name !== '') {
            setSelfiePicture(documentSelfie);
            setHasPictureUploaded(true);
            setReUpload(true);
        } else setHasPictureUploaded(false);
    }, [dispatch, documentSelfie]);

    useEffect(() => {
        dispatch(setSelfiePic(selfiePicture));
    }, [dispatch, selfiePicture]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setPictureError('');
        setUploadError('');
        setIsDisabled(true);

        if (pictureError === '') {
            submitKyc();
        } else {
            setIsDisabled(false);
        }
    };

    const handleBack = () => {
        navigate(routes.kycProofOfAddess);
    };

    const handleReupload = (e: any) => {
        e.preventDefault();
        setHasPictureUploaded(false);
        setSelfiePicture({});
        setReUpload(false);
        setIsDisabled(true);
        setPictureError('');
        setUploadError('');
        setUploadPercentage(0);
        dispatch(setSelfiePic(new File([], '')));
    };

    return (
        <section className={styles.stepContainer}>
            <div className={styles.innerStepContainer}>
                <h3 className={styles.pageTitle}>Finish Verification</h3>
                <StepIndicator step={4} handleClick={handleBack} />
                <p>To complete the face ID verification, please take a photo with your camera and upload it here.</p>
                <form noValidate>
                    {hasPictureUploaded ? (
                        <UploadSucceed />
                    ) : (
                        <UploadDocument
                            setValue={setSelfiePicture}
                            name="upload2"
                            setErrors={setPictureError}
                            setHasUploaded={setHasPictureUploaded}
                            setReUpload={setReUpload}
                        >
                            Upload a photo of <span>your face</span>
                        </UploadDocument>
                    )}
                    {reUpload && (
                        <div className={`${styles.buttonContainer} ${styles.buttonReupload}`}>
                            <Button dark onClick={handleReupload} className={styles.reuploadBtn}>
                                <img src={redoActionIcon} alt="re do action icon" />
                                <span>Reupload photos</span>
                            </Button>
                        </div>
                    )}
                    <div className={`${styles.buttonContainer} ${styles.buttonNext}`}>
                        <Button dark={false} onClick={handleSubmit} isDisabled={!hasPictureUploaded || isDisabled}>
                            Finish
                        </Button>
                    </div>
                    {showPercentageBar && (
                        <div className={styles.percentageContainer}>
                            <div style={{ width: `${uploadPercentage}%` }}>{uploadPercentage}%</div>
                        </div>
                    )}
                    <div className={styles.messageContainer}>
                        {pictureError !== '' && <MessageBox text={pictureError} type="error" />}
                        {uploadError !== '' && <MessageBox text={uploadError} type="error" />}
                    </div>
                </form>
            </div>
        </section>
    );
};

export default FinishVerification;
